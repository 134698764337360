import React from "react";
import "./Popups.css";

const Areyousure = ({ isOpen, onNo, onYes, popupTitle }) => {
  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-500">
        <h2>{popupTitle}</h2>

        <button type="button" className="big_button mr20" onClick={onYes}>
          Yes
        </button>
        <button type="button" className="big_button button_orange" onClick={onNo}>
          No
        </button>
      </div>
    </div>
  );
};

export default Areyousure;
