import React from "react";
import "./Facebookgroups.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import SingleFbGroup from "./SingleFbGroup";
import CreateGroup from "./CreateGroup";
import CreateFolder from "./CreateFolder";
import GroupQuestions from "./GroupQuestions";
import Areyousure from "../../../components/popups/Areyousure";
import { useSearchParams } from "react-router-dom";
import UpdateGroup from "./UpdateGroup";
import Spinner from "../../../components/spinner/Spinner";
import SelectUsersToJoin from "./SelectUsersToJoin";

const Facebookgroups = () => {
  const [searchParams] = useSearchParams();
  let account_id = searchParams.get("account_id");
  // fetch all groups list
  const [foldersList, setfoldersList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);

  const [deleteId, setdeleteId] = useState(null);
  const [deleteFolderId, setdeleteFolderId] = useState(null);
  const [isOpenDelete, setisOpenDelete] = useState(false);
  const [isOpenDeleteFolder, setisOpenDeleteFolder] = useState(false);
  const [isPopupQuestionsOpen, setisPopupQuestionsOpen] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(0);
  const [joinGroupStatus, setJoinGroupStatus] = useState("");

  useEffect(() => {
    fetchgroupsList();
    // Call fetchData initially and then every 10 seconds (10000 milliseconds)
    const intervalId = setInterval(fetchgroupsList, 10000);

    // Clean up the interval to avoid memory leaks
    return () => clearInterval(intervalId);
  }, []);

  const fetchgroupsList = async () => {
    try {
      let URL = `${process.env.REACT_APP_BASE_URL}api/facebook/groups`;
      // api needs some modification to send corrent data for account_id
      if (account_id) {
        URL = `${process.env.REACT_APP_BASE_URL}api/facebook/groups?account_id=${account_id}`;
      }
      const response = await fetch(URL);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setfoldersList(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // Group create
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupFolderOpen, setIsPopupFolderOpen] = useState(false);
  // update group folders
  const [isUpdatePopupOpen, setIsUpdatePopupOpen] = useState(false);
  const [groupToUpdate, setGroupToUpdate] = useState({});

  // State to hold selected group IDs
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [isOpenSelectUsers, setIsOpenSelectUsers] = useState(false);

  const handleCheckboxChange = (groupId, checked) => {
    if (checked) {
      setSelectedGroups([...selectedGroups, groupId]);
    } else {
      setSelectedGroups(selectedGroups.filter((id) => id !== groupId));
    }
  };
  if (loading) {
    // return <p>Loading...</p>;
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const doOpenDelete = (folder_id, group_id) => {
    setdeleteId(group_id);
    setdeleteFolderId(folder_id);
    setisOpenDelete(true);
  };

  const doDelete = async () => {
    let data = {
      group_id: deleteId,
      folder_id: deleteFolderId,
    };
    fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/remove_group_from_folder/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setisOpenDelete(false);
        fetchgroupsList();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const doOpenDeleteFolder = (folder_id) => {
    setdeleteId(folder_id);
    setisOpenDeleteFolder(true);
  };

  const doOpenQuestions = (group_id) => {
    setSelectedGroupId(group_id);
    setisPopupQuestionsOpen(true);
  };

  const doDeleteFolder = async () => {
    fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/folders/` + deleteId, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        setisOpenDeleteFolder(false);
        fetchgroupsList();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleJoinFbGroupsClick = async () => {
    if (selectedGroups && selectedGroups.length > 0) {
      setIsOpenSelectUsers(true);
    } else {
      setJoinGroupStatus("Select at least on group to join.");
    }
  };

  const doOpenUpdate = (group) => {
    setGroupToUpdate(group);
    setIsUpdatePopupOpen(true);
  };
  return (
    <div>
      <div className="top_buttons">
        <button className="big_button mr20" type="button">
          <Link to="/search-fb-groups">Search Group </Link>
        </button>
        <button
          className="big_button mr20"
          id="showGroupPopup"
          type="button"
          onClick={() => {
            setIsPopupOpen(true);
          }}
        >
          Create New Group
        </button>
        <button
          className="big_button"
          id="showGroupPopup"
          type="button"
          onClick={() => {
            setIsPopupFolderOpen(true);
          }}
        >
          Create New Folder
        </button>
      </div>
      <CreateGroup
        isOpen={isPopupOpen}
        onClose={() => {
          setIsPopupOpen(false);
        }}
        fetchgroupsList={fetchgroupsList}
        foldersList={foldersList}
      />
      {isUpdatePopupOpen && (
        <UpdateGroup
          isOpen={isUpdatePopupOpen}
          onClose={() => {
            setIsUpdatePopupOpen(false);
          }}
          fetchgroupsList={fetchgroupsList}
          foldersList={foldersList}
          groupToUpdate={groupToUpdate}
        />
      )}
      <CreateFolder
        isOpen={isPopupFolderOpen}
        onClose={() => {
          setIsPopupFolderOpen(false);
        }}
        fetchgroupsList={fetchgroupsList}
      />

      <GroupQuestions
        isOpen={isPopupQuestionsOpen}
        onClose={() => {
          setisPopupQuestionsOpen(false);
        }}
        group_id={selectedGroupId}
      />

      <Areyousure
        isOpen={isOpenDelete}
        onNo={() => {
          setisOpenDelete(false);
        }}
        onYes={doDelete}
        popupTitle="Are you sure you want to remove this group?"
      />
      <Areyousure
        isOpen={isOpenDeleteFolder}
        onNo={() => {
          setisOpenDeleteFolder(false);
        }}
        onYes={doDeleteFolder}
        popupTitle="Are you sure you want to remove this folder?"
      />
      {isOpenSelectUsers && (
        <SelectUsersToJoin
          isOpen={isOpenSelectUsers}
          onClose={() => {
            setIsOpenSelectUsers(false);
          }}
          selectedGroups={selectedGroups}
          setJoinGroupStatus={setJoinGroupStatus}
        />
      )}

      <div className="active_list_container">
        {foldersList && foldersList.length > 0 ? (
          foldersList.map((folder) => (
            <div key={folder.folder_id}>
              <div className="groups_folder">
                <div>{folder.folder_name}</div>
              </div>
              <div className="active_lists">
                {folder.groups && folder.groups.map((oneGroup) => <SingleFbGroup key={oneGroup.id} folder_id={folder.folder_id} singleFbGroup={oneGroup} onOpenDelete={doOpenDelete} onOpenQuestions={doOpenQuestions} onOpenUpdate={doOpenUpdate} foldersList={foldersList} onCheckboxChange={handleCheckboxChange} selected={selectedGroups.includes(oneGroup.id)} />)}

                {folder.groups && folder.groups.length === 0 && (
                  <div>
                    <div className="gray_empty">
                      No Groups
                      <div
                        className="small_button button_orange mrl100"
                        onClick={() => {
                          doOpenDeleteFolder(folder.folder_id);
                        }}
                      >
                        Delete Folder
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <h1>No Folder or Groups found!</h1>
        )}

        <div className="post-button-container">
          <div className="post-button-div">
            <button id="joinFbGroups" onClick={handleJoinFbGroupsClick} className={`big_button ${buttonClicked ? "disabledButton" : ""}`}>
              Join facebook groups
            </button>
            <div id="join_groups_status">{joinGroupStatus}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facebookgroups;
