import React, { useState } from "react";

const CreateHomeLink = ({ isOpen, onClose, fetchHomeLinks, input_link, setSearchStatus }) => {
  const [formData, setFormData] = useState({});
  const [apiResponse, setApiResponse] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const startLoadingAPI = (e) => {
    e.preventDefault();
    try {
      // Disable the button
      setButtonClicked(true);
      let data = {
        title: formData.title,
        url: input_link,
        sheet_id: formData.all_google_sheet_id,
        sold_sheet_id: formData.sold_google_sheet_id,
        for_sale_sheet_id: formData.for_sale_google_sheet_id,
        back_sheet_id: formData.back_google_sheet_id,
        pending_sheet_id: formData.pending_google_sheet_id,
        off_sheet_id: formData.off_market_google_sheet_id,
        has_google_sheet: isChecked,
      };

      fetch(`${process.env.REACT_APP_BASE_URL}api/get-main-listing`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setSearchStatus(data.success);

            onClose();
            fetchHomeLinks();
            // close popup and refresh the list
          } else {
            setApiResponse(data.error);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors
          setApiResponse(error.messag);
        });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Enable the button after 3 seconds
      setTimeout(() => {
        setButtonClicked(false);
      }, 2000);
    }
  };

  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-500">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Enter google sheets links in relevant fields.</h2>

        <form onSubmit={startLoadingAPI}>
          <div>
            <input type="text" id="title" className="input_field" name="title" value={formData.title || ""} onChange={handleChange} placeholder="Enter link title..." />
          </div>

          {isChecked && (
            <>
              <div>
                <input type="text" id="all_google_sheet_id" className="input_field" name="all_google_sheet_id" value={formData.all_google_sheet_id || ""} onChange={handleChange} placeholder="Enter All PROPERTIES google sheet link..." />
              </div>
              <div>
                <input type="text" id="for_sale_google_sheet_id" className="input_field" name="for_sale_google_sheet_id" value={formData.for_sale_google_sheet_id || ""} onChange={handleChange} placeholder="Enter FOR SALE PROPERTIES google sheet link..." />
              </div>
              <div>
                <input type="text" id="sold_google_sheet_id" className="input_field" name="sold_google_sheet_id" value={formData.sold_google_sheet_id || ""} onChange={handleChange} placeholder="Enter SOLD PROPERTIES google sheet link..." />
              </div>
              <div>
                <input type="text" id="back_google_sheet_id" className="input_field" name="back_google_sheet_id" value={formData.back_google_sheet_id || ""} onChange={handleChange} placeholder="Enter BACK ON MARKET PROPERTIES google sheet link..." />
              </div>
              <div>
                <input type="text" id="pending_google_sheet_id" className="input_field" name="pending_google_sheet_id" value={formData.pending_google_sheet_id || ""} onChange={handleChange} placeholder="Enter PENDING and CONTIGENT PROPERTIES google sheet link..." />
              </div>
              <div>
                <input type="text" id="off_market_google_sheet_id" className="input_field" name="off_market_google_sheet_id" value={formData.off_market_google_sheet_id || ""} onChange={handleChange} placeholder="Enter OFF MARKET PROPERTIES google sheet link..." />
              </div>
            </>
          )}
          <div className="home-checkbox-container">
            <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} className="home-checkbox-input" />
            <label>{isChecked ? "Uncheck NOT to save to google sheet" : "Check to save to google sheet"}</label>
          </div>
          <button type="submit" className={`big_button ${buttonClicked ? "disabledButton" : ""}`} disabled={buttonClicked}>
            Start Loading
          </button>

          <p>{apiResponse}</p>
        </form>
      </div>
    </div>
  );
};

export default CreateHomeLink;
