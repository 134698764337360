import React, { useEffect, useState } from "react";
import "./MapSheets.css";
import Areyousure from "../../components/popups/Areyousure";
import Spinner from "../../components/spinner/Spinner";
function MapSheets() {
  const [loading, setLoading] = useState(true);
  const [colLoading, setColLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isOpenDelete, setisOpenDelete] = useState(false);
  const [deleteId, setdeleteId] = useState(null);

  const [firstSheet, setFirstSheet] = useState("");
  const [secondSheet, setSecondSheet] = useState("");
  const [loadColumnsStatus, setLoadColumnsStatus] = useState("");
  const [dataTable, setDataTable] = useState([]);
  const [transferStatus, setTransferStatus] = useState("");
  const [scheduleStatus, setScheduleStatus] = useState("");
  const [activeSearches, setActiveSearches] = useState([]);

  const [buttonClicked, setButtonClicked] = useState(false);

  const ajaxURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    getAllMappedSheets();
  }, []);
  const getAllMappedSheets = async () => {
    try {
      let URL = `${process.env.REACT_APP_BASE_URL}api/get-mapped-sheets`;
      const response = await fetch(URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      if (result) {
        setActiveSearches(result);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadColClick = () => {
    if (firstSheet && secondSheet && firstSheet.startsWith("https://docs.google.com/spreadsheets/") && secondSheet.startsWith("https://docs.google.com/spreadsheets/")) {
      setColLoading(true);
      setButtonClicked(true);

      loadColumns();
    } else {
      setLoadColumnsStatus("Plese provide two valid google sheet links");
    }
  };
  const loadColumns = async () => {
    try {
      setLoadColumnsStatus("Loading provided sheets columns...");
      let URL = `${process.env.REACT_APP_BASE_URL}api/get-google-sheet-cols`;
      fetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ first_sheet: firstSheet, second_sheet: secondSheet }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            const firstSheetCols = data.first_sheet_cols;
            const secondSheetCols = data.second_sheet_cols;
            const newDataTable = [];
            firstSheetCols.forEach((firstCol) => {
              const newRow = [firstCol];
              const dropdownOptions = secondSheetCols.map((secondCol) => <option key={secondCol}>{secondCol}</option>);
              // newRow.push(
              //   <select style={{ width: "100%", height: "100%" }}>
              //     <option value=""></option>
              //     {dropdownOptions}
              //   </select>
              // );
              // newDataTable.push(newRow);
              newRow.push(
                <select id={`select-${firstCol}`} style={{ width: "100%", height: "100%" }}>
                  <option value=""></option>
                  {dropdownOptions}
                </select>
              );
              newDataTable.push(newRow);
            });
            setDataTable(newDataTable);
          } else {
            console.error("Error:", data.error);
            setLoadColumnsStatus(data.error);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setError(error);
        });
    } catch (error) {
      setError(error);
    } finally {
      setColLoading(false);
      setTimeout(() => {
        setButtonClicked(false);
      }, 3000);
    }
  };

  const transferData = () => {
    try {
      setButtonClicked(true);
      let mappedCols = [];
      const table = document.getElementById("dataTable");
      for (let i = 0; i < table.rows.length; i++) {
        const row = table.rows[i];
        const firstSheetCol = row.cells[0].textContent;

        const select = document.getElementById(`select-${firstSheetCol}`);
        const selectedValue = select?.value;
        if (firstSheetCol && selectedValue) {
          const objData = {};
          objData[firstSheetCol] = selectedValue;
          mappedCols.push(objData);
        }
      }
      fetch(ajaxURL + "api/transfer-sheet-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ first_sheet: firstSheet, second_sheet: secondSheet, mappedCols: mappedCols }),
      })
        .then((response) => response.text())
        .then((data) => {
          setTransferStatus(data);
        })
        .catch((error) => {
          console.error("Error:", error);
          setError(error);
        });
    } catch (error) {
      setError(error);
    } finally {
      setTimeout(() => {
        setButtonClicked(false);
      }, 3000);
    }
  };

  const addToSchedule = () => {
    const mappedCols = [];
    const table = document.getElementById("dataTable");
    for (let i = 0; i < table.rows.length; i++) {
      const row = table.rows[i];
      const firstSheetCol = row.cells[0].textContent;
      const select = document.getElementById(`select-${firstSheetCol}`);
      const selectedValue = select?.value;
      if (firstSheetCol && selectedValue) {
        const objData = {};
        objData[firstSheetCol] = selectedValue;
        mappedCols.push(objData);
      }
    }
    fetch(ajaxURL + "api/add-mapped-sheets", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ first_sheet: firstSheet, second_sheet: secondSheet, mappedCols: mappedCols }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setScheduleStatus(data.success);
          setTimeout(getAllMappedSheets, 4000);
        } else if (data.error) {
          setScheduleStatus(data.error);
          setTimeout(getAllMappedSheets, 4000);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const doOpenDelete = (link_id) => {
    setdeleteId(link_id);
    setisOpenDelete(true);
  };

  const doDelete = async () => {
    let data = {
      id: deleteId,
    };
    fetch(`${process.env.REACT_APP_BASE_URL}api/delete-mapped-sheet`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setisOpenDelete(false);
        getAllMappedSheets();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      {isOpenDelete && (
        <Areyousure
          isOpen={isOpenDelete}
          onNo={() => {
            setisOpenDelete(false);
          }}
          onYes={doDelete}
          popupTitle="Are you sure you want to remove this Link?"
        />
      )}
      <div className="top_search"></div>

      <div id="popupDelete" className="popup">
        <div className="popup_container">
          <div style={{ textAlign: "center", marginBottom: "20px" }}>Are you sure you want to delete?</div>
          <div style={{ textAlign: "center" }}>
            <div style={{ display: "inline-block", marginRight: "10px" }} id="yesDelete" className="exitPopup">
              Yes
            </div>
            <div style={{ display: "inline-block" }} id="noClose" className="exitPopup">
              No
            </div>
          </div>
        </div>
      </div>

      <div className="input_fields_container">
        <div>
          <input type="text" className="input_field" value={firstSheet} onChange={(e) => setFirstSheet(e.target.value)} placeholder="Add Sheet 1 link..." />
          <input type="text" className="input_field" value={secondSheet} onChange={(e) => setSecondSheet(e.target.value)} placeholder="Add sheet 2 link..." />
        </div>
        <button className={`big_button ${buttonClicked ? "disabledButton" : ""}`} disabled={buttonClicked} type="button" onClick={handleLoadColClick}>
          Load Columns
        </button>
        <div id="load_status">{loadColumnsStatus}</div>
      </div>

      {colLoading ? (
        <Spinner />
      ) : (
        <div className="table_container">
          <div className="table_div">
            <table id="dataTable">
              <tbody>
                {dataTable.map((row, index) => (
                  <tr key={index}>
                    {row.map((cell, idx) => (
                      <td key={idx}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div>
            <div className="button_div">
              <button className={`big_button ${buttonClicked ? "disabledButton" : ""}`} disabled={buttonClicked} type="button" onClick={transferData}>
                Transfer Data
              </button>
              <div id="transfer_status">{transferStatus}</div>
            </div>
            <div className="button_div">
              <button className="schedule_button" type="button" onClick={addToSchedule}>
                Add to Schedule
              </button>
              <div id="schedule_status">{scheduleStatus}</div>
            </div>
          </div>
        </div>
      )}
      <div className="active_searches_container">
        <div className="active_searches">
          {activeSearches.map((item, index) => (
            <div key={index} className="active_search">
              <div className="as_link">Sheet 1 Link : https://docs.google.com/spreadsheets/d/{item.sheet_data_from}</div>
              <div className="as_link">Sheet 2 Link : https://docs.google.com/spreadsheets/d/{item.sheet_data_to}</div>
              <div
                className="as_delete"
                onClick={() => {
                  doOpenDelete(item.id);
                }}
              >
                &times;
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MapSheets;
