import React, { useEffect, useState } from "react";
import Creatermessagereply from "./Creatermessagereply";
import { useSearchParams } from "react-router-dom";
import "./Facebookmessages.css";
import Spinner from "../../../components/spinner/Spinner";

const Facebookmessages = () => {
  const [searchParams] = useSearchParams();
  let account_id = searchParams.get("account_id");
  const [senderData, setSenderData] = useState({ senderId: "", senderName: "" });
  const [messagesList, setMessagesList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    fetchMessagesList();
  }, [account_id]);

  const fetchMessagesList = async () => {
    try {
      let URL = `${process.env.REACT_APP_BASE_URL}api/facebook/fb_account_messages/${account_id}`;
      const response = await fetch(URL);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setMessagesList(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const onClickReply = (senderid, senderName) => {
    setSenderData({
      ...senderData,
      senderId: senderid,
      senderName: senderName,
    });
    setIsPopupOpen(true);
  };
  if (loading) {
    // return <p>Loading...</p>;
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <div>
      <Creatermessagereply
        isOpen={isPopupOpen}
        onClose={() => {
          setIsPopupOpen(false);
        }}
        account_id={account_id}
        senderData={senderData}
      />

      <div className="active_list_container">
        <div className="active_lists">
          {messagesList && messagesList.length > 0 ? (
            messagesList.map((sender) => (
              <div key={sender.sender_id} className="group_parent_div">
                <div className="active_list mpt0">
                  <div className="group_name_div">{sender.sender_name}</div>
                  <MessageList messages={sender.messages} />
                  <div className="group_stats"></div>
                  <div className="talr">
                    <div
                      className="small_button button_orange"
                      onClick={() => {
                        onClickReply(sender.sender_id, sender.sender_name);
                      }}
                    >
                      Reply
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h1>No Messages found!</h1>
          )}
        </div>
      </div>
    </div>
  );
};

const MessageList = ({ messages }) => {
  return (
    <div>
      {messages &&
        messages.map((message) => (
          <div key={message.id} className={"one_message " + (message.sent_by_me ? "one_message_blue" : "")}>
            <div className="active_message">{message.message_text}</div>
          </div>
        ))}
    </div>
  );
};

export default Facebookmessages;
