import React, { useState } from "react";
import UpdateFbPost from "./UpdateFbPost";

const SingleFbPost = ({ singleFbPost, onOpenDelete, isSelected, onToggleSelect }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleCheckboxChange = () => {
    onToggleSelect(singleFbPost.id, !isSelected);
  };

  // const handleUpdateClick = () => {
  //   setIsPopupOpen(true);
  // };

  return (
    <div>
      <UpdateFbPost
        isOpen={isPopupOpen}
        onClose={() => {
          setIsPopupOpen(false);
        }}
        postToUpdate={singleFbPost}
        // fetchpostsList={fetchpostsList}
      />
      <div className="parent_div">
        <div className="checkbox">
          <input type="checkbox" id={SingleFbPost.id} name="selected_checkbox" checked={isSelected} onChange={handleCheckboxChange} className="selected_checkbox" />
        </div>
        <div className="active_list mrg10">
          <div className="as_link post_text">{singleFbPost.text}</div>
          <div className="tall">
            <div
              className="small_button button_orange"
              onClick={() => {
                onOpenDelete(singleFbPost.id);
              }}
            >
              Delete
            </div>
          </div>
        </div>
        <div className="img_div">
          <img src={`${process.env.REACT_APP_IMAGE_URL}uploads/${singleFbPost.image}`} width="300" height="200" alt=" " />
        </div>
      </div>
    </div>
  );
};

export default SingleFbPost;
