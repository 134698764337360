import React, { useEffect, useState } from "react";
import "./HomeAddressSheet.css";
import Areyousure from "../../components/popups/Areyousure";
import Spinner from "../../components/spinner/Spinner";
import CreateHomeAddressSheet from "./CreateHomeAddressSheet";
import SendToProcessing from "./SendToProcessing";

const HomeAddressSheet = () => {
  const [addressSheets, setAddressSheets] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({});
  const [searchStatus, setSearchStatus] = useState("");
  const [isOpenDelete, setisOpenDelete] = useState(false);
  const [deleteId, setdeleteId] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isProcessingPopupOpen, setIsProcessingPopupOpen] = useState(false);
  const [sheetToProcess, setSheetToProcess] = useState({});
  useEffect(() => {
    fetchAddressSheets();
    // Call fetchData initially and then every 10 seconds (10000 milliseconds)
    const intervalId = setInterval(fetchAddressSheets, 10000);
    // Clean up the interval to avoid memory leaks
    return () => clearInterval(intervalId);
  }, []);

  // get list of all links
  const fetchAddressSheets = async () => {
    try {
      let URL = `${process.env.REACT_APP_BASE_URL}api/get-homes-address-sheets`;

      const response = await fetch(URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setAddressSheets(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleContinueClick = async () => {
    if (!formData.sheet_id || !formData.process_sheet_id) {
      setSearchStatus("Provide valid sheet links for both fields..");
    } else {
      setIsPopupOpen(true);
    }
  };

  const handleSendToProcessClick = async (data) => {
    setSheetToProcess(data);
    setIsProcessingPopupOpen(true);
  };
  const doOpenDelete = (id) => {
    setdeleteId(id);
    setisOpenDelete(true);
  };

  const doDelete = async () => {
    let data = {
      id: deleteId,
    };
    fetch(`${process.env.REACT_APP_BASE_URL}api/delete-homes-address-sheets`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setisOpenDelete(false);
        fetchAddressSheets();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      {isOpenDelete && (
        <Areyousure
          isOpen={isOpenDelete}
          onNo={() => {
            setisOpenDelete(false);
          }}
          onYes={doDelete}
          popupTitle="Are you sure you want to remove this Link?"
        />
      )}

      {isPopupOpen && (
        <CreateHomeAddressSheet
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          fetchAddressSheets={fetchAddressSheets}
          sheet_id={formData.sheet_id}
          process_sheet_id={formData.process_sheet_id}
          setSearchStatus={setSearchStatus}
        />
      )}
      {isProcessingPopupOpen && (
        <SendToProcessing
          isOpen={isProcessingPopupOpen}
          onClose={() => {
            setIsProcessingPopupOpen(false);
          }}
          fetchAddressSheets={fetchAddressSheets}
          sheetData={sheetToProcess}
          setSearchStatus={setSearchStatus}
        />
      )}

      {/* <div className="top_search">
        <div className="top_search_content">
          <input type="text" id="sheet_id" className="input_link" name="sheet_id" value={formData.sheet_id || ""} onChange={handleChange} placeholder="Enter google sheet link..." />
          <div className="search_icon" onClick={handleContinueClick}>
            Continue
          </div>
        </div>
        <div className="top_search_content" id="search_status">
          {searchStatus}
        </div>
      </div> */}

      <div className="input_fields_container">
        <div>
          <input type="text" id="sheet_id" name="sheet_id" className="input_field" value={formData.sheet_id || ""} onChange={handleChange} placeholder="Enter address google sheet link..." />
          <input type="text" id="process_sheet_id" name="process_sheet_id" className="input_field" value={formData.process_sheet_id || ""} onChange={handleChange} placeholder="Enter processing google sheet link..." />
        </div>
        <button className="sheets_continue_button" type="button" onClick={handleContinueClick}>
          Continue
        </button>
        <div id="search_status">{searchStatus}</div>
      </div>

      <div className="address_sheets_container">
        {addressSheets &&
          addressSheets.map((item, index) => (
            <div key={index} className="active_search">
              <div>
                <div className="link_title">{item.title ? item.title : "No Title..."}</div>
                <div className="address_sheet_link">Sheet Link : https://docs.google.com/spreadsheets/d/{item.sheet_id}</div>
                <div
                  className="address_sheet_delete"
                  onClick={() => {
                    doOpenDelete(item.id);
                  }}
                >
                  &times;
                </div>
              </div>
              <div className="mb10">Total Addresses: {item.address_count}</div>
              {/* {item.status === "loading_address" && (
                <div className="mb10">
                  Status: <span className="status_text"> Loading Addresses ...</span>
                </div>
              )} */}

              <div className="mb10">
                Status: <span className="status_text">{item.status}</span>
              </div>
              <div className="address_sheet_buttons">
                <a href={`https://docs.google.com/spreadsheets/d/${item.sheet_id}/edit#gid=0`} target="_blank" rel="noreferrer">
                  <div className="google_sheet_button">Addresses Google Sheet</div>
                </a>
                <a href={`https://docs.google.com/spreadsheets/d/${item.process_sheet_id}/edit#gid=0`} target="_blank" rel="noreferrer">
                  <div className="google_sheet_button">Processing Google Sheet</div>
                </a>

                {item.status === "addresses_loaded" && (
                  <button className="send_processing_button" type="button" onClick={() => handleSendToProcessClick(item)}>
                    Send to Processing
                  </button>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default HomeAddressSheet;
