import React, { useState } from "react";

const Creatermessagereply = ({ isOpen, onClose, account_id, senderData }) => {
  const [formData, setFormData] = useState({});
  const [apiResponse, setApiResponse] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const submitMessageReply = (e) => {
    e.preventDefault();

    let data = {
      account_id: account_id,
      sender_id: senderData.senderId,
      reply_text: formData.reply_text,
    };

    fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/fb_account_messages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // close popup and refresh the list
          onClose();
        } else {
          setApiResponse(data.error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors
        setApiResponse(error.messag);
      });
  };
  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-500">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Replying to {senderData.senderName}</h2>

        <form onSubmit={submitMessageReply}>
          <div>
            <textarea type="text" rows="10" cols="15" id="reply_text" className="input_field" name="reply_text" placeholder="Write your reply..." value={formData.reply_text || ""} onChange={handleChange} />
          </div>

          <button type="submit" className="big_button">
            Submit
          </button>

          <p>{apiResponse}</p>
        </form>
      </div>
    </div>
  );
};

export default Creatermessagereply;
