import React, { useState } from "react";
import "./OwnerLinks.css";
const CreateOwnerLink = ({ isOpen, onClose, fetchOwnerinks, input_link, setSearchStatus }) => {
  let link_type = "";
  if (input_link.includes("zillow")) {
    link_type = "zillow";
  } else if (input_link.includes("fsbo")) {
    link_type = "fsbo";
  } else if (input_link.includes("forsalebyowner")) {
    link_type = "forsalebyowner";
  } else if (input_link.includes("byowner")) {
    link_type = "byowner";
  }

  const [formData, setFormData] = useState({});
  const [apiResponse, setApiResponse] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [selectedDistance, setSelecdDistance] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDistanceChange = (event) => {
    setSelecdDistance(event.target.value);
  };

  const handleStartLoading = (e) => {
    e.preventDefault();
    const checkBoxForm = new FormData(e.target);

    const zillowAddressCheckboxValue = checkBoxForm.get("zillow_address_checkbox");

    const isChecked = zillowAddressCheckboxValue === "on";

    // Use isChecked in your logic
    if (isChecked) {
      let data = {
        title: formData.title,
        url: input_link,
        sheet_id: formData.sheet_id,
      };
      syncZillowAddressesAPI(data);
    } else {
      let data = {
        title: formData.title,
        url: input_link,
        sheet_id: formData.sheet_id,
        agent_sheet_id: formData.agent_sheet_id,
      };

      if (link_type === "fsbo") {
        data["fsboSearchQuery"] = formData.fsbo_search_query;
        data["distance"] = selectedDistance;
      }
      startLoadingAPI(data);
    }
  };

  const startLoadingAPI = (data) => {
    try {
      // Disable the button
      setButtonClicked(true);

      fetch(`${process.env.REACT_APP_BASE_URL}api/get-zillow-listing`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setSearchStatus(data.success);

            onClose();
            fetchOwnerinks();
            // close popup and refresh the list
          } else {
            setApiResponse(data.error);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors
          setApiResponse(error.messag);
        });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Enable the button after 3 seconds
      setTimeout(() => {
        setButtonClicked(false);
      }, 2000);
    }
  };

  const syncZillowAddressesAPI = (data) => {
    try {
      // Disable the button
      setButtonClicked(true);

      fetch(`${process.env.REACT_APP_BASE_URL}api/sync-zillow-addresses-sheet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setSearchStatus(data.success);

            onClose();
            fetchOwnerinks();
            // close popup and refresh the list
          } else {
            setApiResponse(data.error);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors
          setApiResponse(error.messag);
        });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Enable the button after 3 seconds
      setTimeout(() => {
        setButtonClicked(false);
      }, 2000);
    }
  };

  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-500">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Enter google sheets links in relevant fields.</h2>

        <form onSubmit={handleStartLoading}>
          {link_type === "fsbo" && (
            <>
              <div>
                <input type="text" id="fsbo_search_query" className="input_field" name="fsbo_search_query" value={formData.fsbo_search_query || ""} onChange={handleChange} placeholder="Enter FSBO Search Query..." />
              </div>
              <div>
                <select id="dropdown" className="input_field" value={selectedDistance} onChange={handleDistanceChange}>
                  <option value="0">Any distance</option>
                  <option value="25">25 miles</option>
                  <option value="50">50 miles</option>
                  <option value="75">75 miles</option>
                  <option value="100">100 miles</option>
                  <option value="125">125 miles</option>
                  <option value="150">150 miles</option>
                  <option value="175">175 miles</option>
                  <option value="200">200 miles</option>
                  <option value="400">400 miles</option>
                </select>
              </div>
            </>
          )}
          <div>
            <input type="text" id="title" className="input_field" name="title" value={formData.title || ""} onChange={handleChange} placeholder="Enter link title..." />
          </div>
          <div>
            <input type="text" id="sheet_id" className="input_field" name="sheet_id" value={formData.sheet_id || ""} onChange={handleChange} placeholder="Enter google sheet link..." />
          </div>

          <div>
            <input type="text" id="agent_sheet_id" className="input_field" name="agent_sheet_id" value={formData.agent_sheet_id || ""} onChange={handleChange} placeholder="Enter Agent sheet link..." />
          </div>

          {link_type === "zillow" && (
            <div className="owner_checkbox_item">
              <input type="checkbox" id="zillow_address_checkbox" name="zillow_address_checkbox" />
              <label className="checkbox_label" htmlFor="zillow_address_checkbox">
                Sync Addresses to google sheet{" "}
              </label>
            </div>
          )}
          <button type="submit" className={`big_button ${buttonClicked ? "disabledButton" : ""}`} disabled={buttonClicked}>
            Start Loading {link_type}
          </button>

          <p>{apiResponse}</p>
        </form>
      </div>
    </div>
  );
};

export default CreateOwnerLink;
