import React, { useState } from "react";

const CreateHomeAddressSheet = ({ isOpen, onClose, fetchAddressSheets, sheet_id, process_sheet_id, setSearchStatus }) => {
  const [formData, setFormData] = useState({});
  const [apiResponse, setApiResponse] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const startLoadingAPI = (e) => {
    e.preventDefault();
    try {
      // Disable the button
      setButtonClicked(true);
      let data = {
        sheet_id: sheet_id.trim(),
        process_sheet_id: process_sheet_id.trim(),
        title: formData.title.trim(),
      };

      fetch(`${process.env.REACT_APP_BASE_URL}api/get-homes-data-for-sheet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setSearchStatus(data.success);

            onClose();
            fetchAddressSheets();
            // close popup and refresh the list
          } else {
            setApiResponse(data.error);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors
          setApiResponse(error.messag);
        });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Enable the button after 3 seconds
      setTimeout(() => {
        setButtonClicked(false);
      }, 2000);
    }
  };

  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-500">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Provide data in relevant fields.</h2>

        <form onSubmit={startLoadingAPI}>
          <div>
            <input type="text" id="title" className="input_field" name="title" value={formData.title || ""} onChange={handleChange} placeholder="Enter Title" />
          </div>

          <button type="submit" className={`big_button ${buttonClicked ? "disabledButton" : ""}`} disabled={buttonClicked}>
            Start Loading
          </button>

          <p>{apiResponse}</p>
        </form>
      </div>
    </div>
  );
};

export default CreateHomeAddressSheet;
