import React, { useState } from "react";
import "./FacebookImport.css";

const FacebookImport = () => {
  const [apiResponse, setApiResponse] = useState("");

  const [proxyList, setProxyList] = useState("");
  const [profilesList, setProfilesList] = useState("");

  const handleProxyListChange = (e) => {
    setProxyList(e.target.value);
  };

  const handleProfilesListChange = (e) => {
    setProfilesList(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (proxyList && profilesList) {
      let allProxies = proxyList.split("\n");

      let trimmedProxies = allProxies.map((item) => item.trim()).filter((item) => item.trim() !== "");

      let allProfileCredentials = [];
      let splitProfiles = profilesList.split("\n");
      let trimmedProfiles = splitProfiles.map((item) => item.trim()).filter((item) => item.trim() !== "");

      for (let oneProfile of trimmedProfiles) {
        let profileCredentials = oneProfile.split(":");
        let email = profileCredentials[0];
        let password = profileCredentials[1];

        allProfileCredentials.push({
          email: email,
          password: password,
        });
      }
      if (trimmedProxies && trimmedProxies.length > 0 && allProfileCredentials && allProfileCredentials.length > 0) {
        let formData = {
          proxy_list: trimmedProxies,
          profile_list: allProfileCredentials,
        };
        startImportingAPI(formData);
      } else {
        setApiResponse("Kindly enter proxies and profiles in correct line separated!");
      }
    } else {
      setApiResponse("Proxies list and profiles list are required");
    }
  };

  const startImportingAPI = (formData) => {
    fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/match_facebook_profile_proxy`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setApiResponse(data.success);
        } else {
          setApiResponse(data.error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors
        setApiResponse(error.messag);
      });
  };
  return (
    <div className="maindiv">
      <form onSubmit={handleSubmit}>
        <div className="fbImportTextAreas">
          <div className="fbImportSingleTextArea">
            <h3 className="heading">Add Proxies</h3>
            <div>
              <textarea id="proxyList" className="input_field" name="proxyList" rows="35" cols="30" placeholder="Enter line separated proxies list" value={proxyList} onChange={handleProxyListChange}></textarea>
            </div>
          </div>
          <div className="fbImportSingleTextArea">
            <h3 className="heading">Add Facebook Profiles</h3>
            <div>
              <textarea id="profilesList" className="input_field" name="profilesList" rows="35" cols="30" placeholder="Enter line separated profiles list" value={profilesList} onChange={handleProfilesListChange}></textarea>
            </div>
          </div>
        </div>

        <button type="submit" className="big_button">
          Start Importing
        </button>
        <p>{apiResponse}</p>
      </form>
    </div>
  );
};

export default FacebookImport;
