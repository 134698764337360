import React, { useRef, useState } from "react";

const UpdateFbPost = ({ isOpen, onClose, postToUpdate }) => {
  const [apiResponse, setApiResponse] = useState("");
  const [postText, setPostText] = useState(postToUpdate.text);
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);

  const handlePostTextChange = (e) => {
    setPostText(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if both name and image are provided
    if (postText) {
      const formData = new FormData();
      formData.append("text", postText);
      formData.append("image", image);

      updateFbPostAPI(formData);
    } else {
      console.error("Name and image are required");
    }
  };

  const updateFbPostAPI = (formData) => {
    fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/posts/${postToUpdate.id}`, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          onClose();
          //   fetchpostsList();
          // close popup and refresh the list
          // Clear form fields
          setPostText("");
          setImage(null);
          // clear the file input field
          fileInputRef.current.value = "";
        } else {
          setApiResponse(data.error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors
        setApiResponse(error.messag);
      });
  };

  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-500">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Update Post</h2>

        <form onSubmit={handleSubmit}>
          <div>
            <textarea id="post_text" className="input_field" name="post_text" rows="15" cols="30" placeholder="Enter post text here" value={postText} onChange={handlePostTextChange}></textarea>
          </div>
          <div>
            <input type="file" id="image" className="input_field" name="image" accept="image/*" onChange={handleImageChange} ref={fileInputRef} />
          </div>

          <button type="submit" className="big_button">
            Submit
          </button>

          <p>{apiResponse}</p>
        </form>
      </div>
    </div>
  );
};

export default UpdateFbPost;
