import React, { useEffect, useState } from "react";
import Spinner from "../../../components/spinner/Spinner";

const PostToFacebook = ({ isOpen, onClose, selectedPosts }) => {
  const [foldersList, setFoldersList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectFolderStatus, setSelectFolderStatus] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    fetchFoldersList();
  }, [isOpen]);
  const fetchFoldersList = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/groups`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setFoldersList(result);
    } catch (error) {
      setError(error);
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const [selectedFolders, setSelectedFolders] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const handleFolderCheckboxChange = (id) => {
    id = Number(id);
    if (id) {
      setSelectFolderStatus("");
      setSelectedFolders((prevSelectedFolders) => {
        if (prevSelectedFolders.includes(id)) {
          updateFolderGroupsCheck(id, false);
          return prevSelectedFolders.filter((folderId) => folderId !== id);
        } else {
          updateFolderGroupsCheck(id, true);
          return [...prevSelectedFolders, id];
        }
      });
    }
  };

  const updateFolderGroupsCheck = (id, flag) => {
    let selectedFolder = foldersList.filter((folder) => folder.folder_id === id);
    let selectedGroups = selectedFolder[0]?.groups;
    if (selectedGroups) {
      selectedGroups.forEach(async (group) => {
        if (flag) {
          await checkAllGroups(group.id);
        } else {
          await unCheckAllGroups(group.id);
        }
      });
    }
  };

  const checkAllGroups = (group_id) => {
    setSelectedGroups((prevSelectedGroup) => {
      return [...prevSelectedGroup, group_id];
    });
  };

  const unCheckAllGroups = (group_id) => {
    setSelectedGroups((prevSelectedGroup) => {
      return prevSelectedGroup.filter((groupId) => groupId !== group_id);
    });
  };

  const handleGroupCheckboxChange = (id) => {
    id = Number(id);
    if (id) {
      setSelectedGroups((prevSelectedGroup) => {
        if (prevSelectedGroup.includes(id)) {
          return prevSelectedGroup.filter((groupId) => groupId !== id);
        } else {
          return [...prevSelectedGroup, id];
        }
      });
    }
  };

  const handleGetSelectedPosts = async () => {
    let selectedPostsIds = [];
    if (selectedPosts) {
      selectedPostsIds = await Object.keys(selectedPosts).filter((postId) => selectedPosts[postId]);
    }

    const uniqueSelectedGroups = [...new Set(selectedGroups)];

    let data = {
      selectedPosts: selectedPostsIds,
      selectedGroups: uniqueSelectedGroups,
    };
    // console.log("data", data);
    if (selectedPostsIds && selectedPostsIds.length > 0 && selectedGroups && selectedGroups.length > 0) {
      postToFbGroupsApi(data);
    } else {
      // select at least one folder
      setSelectFolderStatus("Select at least one folder or group!");
    }
  };

  const postToFbGroupsApi = async (data) => {
    // Make a POST request
    try {
      // Disable the button
      setButtonClicked(true);
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/run_facebook_bot`, {
        method: "POST",
        // body: JSON.parse(data),
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      if (result.success) {
        setSelectFolderStatus("Posting to selected to selected group folders!");
        setTimeout(() => {
          setSelectFolderStatus("");
          onClose();
        }, 1000);
      } else {
        setSelectFolderStatus(result.error);
      }
    } catch (error) {
      setError(error);
    } finally {
      // Enable the button after 3 seconds
      setTimeout(() => {
        setButtonClicked(false);
      }, 3000);
    }
  };

  if (loading) {
    // return <p>Loading...</p>;
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-500">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Select group folders</h2>
        <div className="active_list_container">
          {foldersList && foldersList.length > 0 ? (
            foldersList.map((folder) => (
              <div key={folder.folder_id} className="checkbox_list">
                <div className="checkbox">
                  <input type="checkbox" id={folder.folder_id} name="selected_checkbox" checked={selectedFolders.includes(folder.folder_id)} onChange={() => handleFolderCheckboxChange(folder.folder_id)} className="selected_checkbox" />
                  <div className="active_list">{folder.folder_name}</div>
                </div>
                {/* Display groups in each folder */}
                {folder.groups &&
                  folder.groups.map((group) => (
                    <div key={group.id} className="groups_list">
                      <div className="checkbox">
                        <input type="checkbox" id={group.id} name="selected_checkbox" checked={selectedGroups.includes(group.id)} onChange={() => handleGroupCheckboxChange(group.id)} className="selected_checkbox" />
                        <div className="active_list">{group.group_name}</div>
                      </div>
                    </div>
                  ))}
              </div>
            ))
          ) : (
            <p>No folders found</p>
          )}
        </div>
        <button className={`big_button ${buttonClicked ? "disabledButton" : ""}`} disabled={buttonClicked} id="postToFbGroups" onClick={handleGetSelectedPosts}>
          Post to facebook groups
        </button>
        <div className="error_msg">{selectFolderStatus}</div>
      </div>
    </div>
  );
};

export default PostToFacebook;
