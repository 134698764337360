import React from "react";
import "./Facebookposts.css";
import { useState, useEffect } from "react";
import Areyousure from "../../../components/popups/Areyousure";
import SingleFbPost from "./SingleFbPost";
import CreateFbPost from "./CreateFbPost";
import PostToFacebook from "./PostToFacebook";
import Spinner from "../../../components/spinner/Spinner";

const Facebookposts = () => {
  const [postsList, setPostsList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isOpenDelete, setisOpenDelete] = useState(false);
  const [isOpenPostToFB, setIsOpenPostToFB] = useState(false);
  const [deleteId, setdeleteId] = useState(0);
  const [selectedPosts, setSelectedPosts] = useState({});
  const [openFolderStatus, setOpenFolderStatus] = useState("");
  const handleToggleSelect = (postId, isSelected) => {
    setSelectedPosts((prevSelectedPosts) => ({
      [postId]: isSelected,
    }));
  };
  useEffect(() => {
    fetchpostsList();
  }, []);

  const fetchpostsList = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/posts`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setPostsList(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const doOpenDelete = (group_id) => {
    setdeleteId(group_id);
    setisOpenDelete(true);
  };
  const doDelete = async () => {
    fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/posts/` + deleteId, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        setisOpenDelete(false);
        fetchpostsList();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleOpenFolderPopup = async () => {
    const hasTruePost = Object.values(selectedPosts).some((value) => value === true);

    if (hasTruePost) {
      setOpenFolderStatus("");

      // open folders selection popup
      setIsOpenPostToFB(true);
    } else {
      setOpenFolderStatus("Please select at least one post!");
    }
  };
  if (loading) {
    // return <p>Loading...</p>;
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      <div className="top_buttons">
        <button
          className="big_button mr20"
          id="showGroupPopup"
          type="button"
          onClick={() => {
            setIsPopupOpen(true);
          }}
        >
          Create New Post
        </button>
      </div>

      <CreateFbPost
        isOpen={isPopupOpen}
        onClose={() => {
          setIsPopupOpen(false);
        }}
        fetchpostsList={fetchpostsList}
      />
      {isOpenPostToFB && (
        <PostToFacebook
          isOpen={isOpenPostToFB}
          onClose={() => {
            setIsOpenPostToFB(false);
          }}
          selectedPosts={selectedPosts}
        />
      )}
      <Areyousure
        isOpen={isOpenDelete}
        onNo={() => {
          setisOpenDelete(false);
        }}
        onYes={doDelete}
        popupTitle="Are you sure you want to remove this Post?"
      />

      <div className="active_list_container">
        {postsList && postsList.length > 0 ? (
          postsList.map((post) => (
            <div key={post.id} className="brbottom">
              <SingleFbPost singleFbPost={post} onOpenDelete={doOpenDelete} isSelected={selectedPosts[post.id] || false} onToggleSelect={handleToggleSelect} />
            </div>
          ))
        ) : (
          <h1>No Post found!</h1>
        )}

        <div className="post-button-container">
          <div className="post-button-div">
            <button className="big_button" id="postToFbGroups" onClick={handleOpenFolderPopup}>
              Post to Folders
            </button>
            <div>{openFolderStatus}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facebookposts;
