import React, { useEffect, useState } from "react";
import "./Aiprompts.css";
import Createaiprompt from "./Createaiprompt";
import Areyousure from "../../components/popups/Areyousure";
import Spinner from "../../components/spinner/Spinner";

const Aiprompts = () => {
  let baseURL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [promptsList, setPromptsList] = useState([]);

  const [isOpenDelete, setisOpenDelete] = useState(false);
  const [deleteId, setdeleteId] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState(null);

  useEffect(() => {
    loadPromptsList();
  }, []);

  const loadPromptsList = async () => {
    try {
      fetch(baseURL + "api/get-ai-prompts")
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setPromptsList(data);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const doOpenDelete = (prompt_id) => {
    setdeleteId(prompt_id);
    setisOpenDelete(true);
  };

  const doDelete = async () => {
    fetch(`${process.env.REACT_APP_BASE_URL}api/ai-prompt-delete/` + deleteId, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        setisOpenDelete(false);
        loadPromptsList();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      <div className="top_buttons">
        <button
          className="big_button mr20"
          id="showGroupPopup"
          type="button"
          onClick={() => {
            setIsPopupOpen(true);
          }}
        >
          Add Prompt
        </button>
      </div>

      <table id="dataTable" className="agent_table aip_table">
        <thead>
          <tr>
            <th style={{ width: "200px" }}>Prompt Name</th>
            <th>Prompt Text</th>
            <th style={{ width: "200px" }}>Update Field</th>
            <th style={{ width: "100px" }}></th>
          </tr>
        </thead>
        <tbody>
          {promptsList &&
            promptsList.map((prompt, index) => (
              <tr key={index}>
                <td>{prompt.prompt_name}</td>
                <td>{prompt.prompt_text}</td>
                <td>{prompt.update_field}</td>
                <td style={{ textAlign: "center" }}>
                  <button
                    className="delete_button del_blue"
                    type="button"
                    onClick={() => {
                      setCurrentPrompt(prompt);
                      setIsPopupOpen(true);
                    }}
                  >
                    Edit
                  </button>
                  <br />
                  <button
                    className="delete_button"
                    type="button"
                    onClick={() => {
                      doOpenDelete(prompt.id);
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <Areyousure
        isOpen={isOpenDelete}
        onNo={() => {
          setisOpenDelete(false);
        }}
        onYes={doDelete}
        popupTitle="Are you sure you want to remove this prompt?"
      />

      {isPopupOpen && (
        <Createaiprompt
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          loadPromptsList={loadPromptsList}
          promptInfo={currentPrompt}
        />
      )}
    </div>
  );
};

export default Aiprompts;
