import React, { useEffect, useState } from "react";
import Spinner from "../../../components/spinner/Spinner";
import "./Facebookgroups.css";
import { MultiSelect } from "react-multi-select-component";

const SelectUsersToJoin = ({ isOpen, onClose, selectedGroups, setJoinGroupStatus }) => {
  const [accountList, setAccountList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectAccountsStatus, setSelectAccountsStatus] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState([]);

  useEffect(() => {
    fetchaccountsList();
  }, []);

  const fetchaccountsList = async () => {
    try {
      let URL = `${process.env.REACT_APP_BASE_URL}api/facebook/accounts`;
      const response = await fetch(URL);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      //   setAccountList(result);
      let transformedArrayData = await result.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setAccountList(transformedArrayData);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  //   const handleAccountCheckboxChange = (id) => {
  //     id = Number(id);
  //     if (id) {
  //       setSelectAccountsStatus("");
  //       setSelectedAccounts((prevSelectedAccounts) => {
  //         if (prevSelectedAccounts.includes(id)) {
  //           return prevSelectedAccounts.filter((folderId) => folderId !== id);
  //         } else {
  //           return [...prevSelectedAccounts, id];
  //         }
  //       });
  //     }
  //   };

  const handleJoinGroupsClick = async () => {
    let selectedAccountsIDs = selectedAccounts.map((item) => item.value);

    let data = {
      selectedAccounts: selectedAccountsIDs,
      selectedGroups: selectedGroups,
    };
    if (selectedAccounts && selectedAccounts.length > 0 && selectedGroups && selectedGroups.length > 0) {
      joinFbGroupsApi(data);
    } else {
      // select at least one folder
      setSelectAccountsStatus("Select at least one account!");
    }
  };

  const joinFbGroupsApi = async (data) => {
    // Make a POST request
    try {
      // Disable the button
      setButtonClicked(true);
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/join_facebook_groups`, {
        method: "POST",
        // body: JSON.parse(data),
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      if (result.success) {
        setSelectAccountsStatus("Selected accounts are joining selected groups!");
        setJoinGroupStatus("Selected accounts are joining selected groups!");
        setTimeout(() => {
          setSelectAccountsStatus("");
          onClose();
        }, 1000);
      } else {
        setSelectAccountsStatus(result.error);
      }
    } catch (error) {
      setError(error);
    } finally {
      // Enable the button after 3 seconds
      setTimeout(() => {
        setButtonClicked(false);
      }, 3000);
    }
  };

  if (loading) {
    // return <p>Loading...</p>;
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-500">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Select Accounts</h2>
        <div className="active_list_container">
          <div className="multi_select">{accountList && accountList.length > 0 && <MultiSelect options={accountList} value={selectedAccounts} onChange={setSelectedAccounts} labelledBy="Select" />}</div>

          {/* {accountList && accountList.length > 0 ? (
            accountList.map((account) => (
              <div key={account.id} className="checkbox_list">
                <div className="checkbox">
                  <input type="checkbox" id={account.id} name="selected_checkbox" checked={selectedAccounts.includes(account.id)} onChange={() => handleAccountCheckboxChange(account.id)} className="selected_checkbox" />
                  <div className="active_list">{account.name}</div>
                </div>
              </div>
            ))
          ) : (
            <p>No accounts found</p>
          )} */}
        </div>
        <button className={`big_button ${buttonClicked ? "disabledButton" : ""}`} disabled={buttonClicked} onClick={handleJoinGroupsClick}>
          Join facebook groups
        </button>
        <div className="error_msg">{selectAccountsStatus}</div>
      </div>
    </div>
  );
};

export default SelectUsersToJoin;
