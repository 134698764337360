import React, { useEffect, useState } from "react";
import "./Facebookgroups.css";

const GroupQuestions = ({ isOpen, onClose, group_id }) => {
  const [questions, setQuestions] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleChange = (e) => {
    const elm = e.target;
    const quest_id = elm.getAttribute("data-id");
    const answer = elm.value;

    var questionsChange = [...questions];
    questionsChange[quest_id].answer = answer;
    setQuestions(questionsChange);
  };

  const handleChangeCheckbox = (e) => {
    const elm = e.target;
    const quest_id = elm.getAttribute("data-id");
    const option_id = elm.getAttribute("data-option-id");
    const answer_checked = elm.checked;

    var questionsChange = [...questions];
    const indexOfQuestion = questionsChange.findIndex((obj) => Number(obj.id) === Number(quest_id));

    const indexOfOption = questionsChange[indexOfQuestion]["options"].findIndex((obj) => Number(obj.id) === Number(option_id));
    questionsChange[indexOfQuestion]["options"][indexOfOption]["checked"] = answer_checked ? 1 : 0;
    setQuestions(questionsChange);
  };

  const handleChangeRadio = (e) => {
    const elm = e.target;
    const quest_id = elm.getAttribute("data-id");
    const option_id = elm.getAttribute("data-option-id");
    // const answer_checked = elm.checked;
    var questionsChange = [...questions];
    const indexOfQuestion = questionsChange.findIndex((obj) => Number(obj.id) === Number(quest_id));
    // const indexOfOption = questionsChange[indexOfQuestion]["options"].findIndex((obj) => Number(obj.id) === Number(option_id));
    const selectedRadioOption = questionsChange[indexOfQuestion]["options"].find((obj) => Number(obj.id) === Number(option_id));

    // questionsChange[indexOfQuestion]["options"][indexOfOption]["checked"] = answer_checked ? 1 : 0;
    questionsChange[indexOfQuestion]["checked"] = selectedRadioOption.id;
    // questionsChange[indexOfQuestion]["options"][indexOfOption]["checked"] = true;

    setQuestions(questionsChange);
  };
  useEffect(() => {
    if (!group_id) {
      return;
    }

    fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/questions/groups/` + group_id).then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      response.json().then((result) => {
        setQuestions(result);
        setIsLoaded(true);
      });
    });
  }, [group_id]);

  const updateAnswers = () => {
    let obj = {};
    // create api object
    const textAnswer = questions.find((obj) => obj.type === "text");
    if (textAnswer) {
      const multipleTexts = questions.filter((obj) => obj.type === "text");
      if (multipleTexts && multipleTexts.length > 0) {
        for (let oneText of multipleTexts) {
          if (obj.text === undefined || !obj.text) {
            obj.text = [];
          }
          obj.text.push({
            question_id: oneText.id,
            answer: oneText.answer,
          });
        }
      }
    }

    // const checkboxAnswer = questions.find((obj) => obj.type === "checkbox");

    // if (checkboxAnswer) {
    //   const selectedOptions = checkboxAnswer.options.filter((obj) => obj.checked === 1);
    //   if (selectedOptions && selectedOptions.length > 0) {
    //     let checkboxOpt = { question_id: checkboxAnswer.id, selected_checkbox: [] };

    //     for (let oneOption of selectedOptions) {
    //       checkboxOpt.selected_checkbox.push(oneOption.id);
    //     }
    //     obj.checkbox = checkboxOpt;
    //   }
    // }

    const checkboxAnswers = questions.filter((obj) => obj.type === "checkbox");

    if (checkboxAnswers && checkboxAnswers.length > 0) {
      for (let singleCheckbox of checkboxAnswers) {
        if (obj.checkbox === undefined) {
          obj.checkbox = [];
        }
        const selectedOptions = singleCheckbox.options.filter((obj) => obj.checked === 1);
        if (selectedOptions && selectedOptions.length > 0) {
          let checkboxOpt = { question_id: singleCheckbox.id, selected_checkbox: [] };
          for (let oneOption of selectedOptions) {
            checkboxOpt.selected_checkbox.push(oneOption.id);
          }
          obj.checkbox.push(checkboxOpt);
        }
      }
      // const selectedOptions = checkboxAnswers.options.filter((obj) => obj.checked === 1);
      // if (selectedOptions && selectedOptions.length > 0) {
      //   let checkboxOpt = { question_id: checkboxAnswers.id, selected_checkbox: [] };

      //   for (let oneOption of selectedOptions) {
      //     checkboxOpt.selected_checkbox.push(oneOption.id);
      //   }
      //   obj.checkbox = checkboxOpt;
      // }
    }
    const radioQuestions = questions.filter((obj) => obj.type === "radio");

    if (radioQuestions && radioQuestions.length > 0) {
      for (let singleRadio of radioQuestions) {
        if (obj.radio === undefined) {
          obj.radio = [];
        }
        const selectedRadio = singleRadio.options.find((obj) => obj.id === singleRadio.checked);
        // const selectedRadio = singleRadio.options.filter((obj) => obj.checked === 1);

        if (selectedRadio) {
          obj.radio.push({
            question_id: singleRadio.id,
            selected_radio: selectedRadio.id,
          });
        }
      }
    }

    // console.log("obj", obj);

    // call api to save answers

    fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/questions/groups/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          onClose();
          // fetchgroupsList();
          // close popup and refresh the list
        } else {
          // setApiResponse(data.error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors
        // setApiResponse(error.messag);
      });
  };

  if (!isLoaded && isOpen) {
    return <p>Loading...</p>;
  }

  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-500">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Group Questions</h2>

        {questions.map((question, ind) => (
          <div key={question.id}>
            {question.type === "text" && (
              <div>
                <div className="active_list">
                  <div className="question_div">{question.question}</div>
                  <div>
                    <input type="text" data-id={ind} data-question-id={question.id} className="input_field" value={question.answer} placeholder="Answer" onChange={handleChange} />
                  </div>
                </div>
              </div>
            )}

            {question.type === "radio" && (
              <div>
                <div className="active_list">
                  <div className="question_div">{question.question}</div>
                  <div>
                    <form id={"radio_box" + question.id}>
                      {question.options.map((option) => (
                        <div key={option.id} className="radio_list">
                          <input type="radio" data-id={question.id} id={"radio_answer_" + option.id} data-option-id={option.id} name={"radio_answer" + question.id} value={option.id} checked={option.id === question.checked} onChange={handleChangeRadio} />
                          <label htmlFor={"radio_answer_" + option.id}>{option.option_text}</label>
                        </div>
                      ))}
                    </form>
                  </div>
                </div>
              </div>
            )}

            {question.type === "checkbox" && (
              <div>
                <div className="active_list">
                  <div className="question_div">{question.question}</div>
                  <div>
                    <form id={"checkbox_box" + question.id}>
                      {question.options.map((option, ind2) => (
                        <div key={option.id} className="radio_list">
                          <input type="checkbox" data-id={question.id} data-option-id={option.id} id={"checkbox_answer_" + option.id} name={"checkbox_answer" + question.id} checked={!!option.checked} onChange={handleChangeCheckbox} />
                          <label htmlFor={"checkbox_answer_" + option.id}>{option.option_text}</label>
                        </div>
                      ))}
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}

        <button type="button" className="big_button" onClick={updateAnswers}>
          Save Answers
        </button>
      </div>
    </div>
  );
};

export default GroupQuestions;
