import React, { useEffect, useState } from "react";

const SendToProcessing = ({ isOpen, onClose, fetchAddressSheets, sheetData, setSearchStatus }) => {
  const [apiResponse, setApiResponse] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const [buttonClicked, setButtonClicked] = useState(false);
  const [serversList, setServersList] = useState([]);
  // const serversList = [
  //   // { id: 1, name: "Server # 1", value: "server_1" },
  //   // { id: 2, name: "Server # 2", value: "server_2" },
  //   { id: 1, url: "http://206.72.206.58:8080/api/fillup", name: "Server # 1" },
  //   { id: 2, url: "http://67.211.214.178:8080/api/fillup", name: "Server # 2" },
  // ];
  useEffect(() => {
    fetchServersList();

    // return () => {
    //   second
    // }
  }, []);

  const fetchServersList = async () => {
    try {
      let URL = `${process.env.REACT_APP_BASE_URL}api/get-all-address-sheet-servers`;

      const response = await fetch(URL);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setServersList(result);
    } catch (error) {
      // setError(error);
    } finally {
      // setLoading(false);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(Number(event.target.value));
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();
    if (!selectedOption || !sheetData.sheet_id || !sheetData.process_sheet_id) {
      if (!selectedOption) setApiResponse("Please select a server before submitting request.");
      else if (!sheetData.sheet_id) setApiResponse("No google sheet found!.");
      else if (!sheetData.process_sheet_id) setApiResponse("No processing google sheet found!.");
    } else {
      let postData = {
        sheet_id: sheetData.sheet_id.trim(),
        process_sheet_id: sheetData.process_sheet_id.trim(),
        selected_server: selectedOption,
      };

      submitAPI(postData);
    }
  };

  const submitAPI = (postData) => {
    try {
      // Disable the button
      setButtonClicked(true);

      fetch(`${process.env.REACT_APP_BASE_URL}api/send-address-sheet-to-process`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setSearchStatus(data.success);

            onClose();
            fetchAddressSheets();
            // close popup and refresh the list
          } else {
            setApiResponse(data.error);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors
          setApiResponse(error.messag);
        });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Enable the button after 3 seconds
      setTimeout(() => {
        setButtonClicked(false);
      }, 2000);
    }
  };
  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-500">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Select a server from list below.</h2>

        {serversList && serversList.length > 0 ? (
          serversList.map((server) => (
            <div key={server.id} className="radio_list">
              <label>
                <input type="radio" id={server.id} value={server.id} checked={selectedOption === server.id} onChange={handleOptionChange} />
                {server.title}
              </label>
            </div>
          ))
        ) : (
          <p>No Servers found!</p>
        )}

        <button type="submit" className={`big_button ${buttonClicked ? "disabledButton" : ""}`} disabled={buttonClicked} onClick={handleSubmitClick}>
          Submit
        </button>
        <div className="error_text">{apiResponse}</div>
      </div>
    </div>
  );
};

export default SendToProcessing;
