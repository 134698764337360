import React, { useEffect, useState } from "react";
import "./Craigslist.css";
import CreateCraigslistPost from "./CreateCraigslistPost";
import Areyousure from "../../components/popups/Areyousure";
import Spinner from "../../components/spinner/Spinner";

const Craigslist = () => {
  let baseURL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [craigslistList, setCraigslistList] = useState([]);

  const [isOpenDelete, setisOpenDelete] = useState(false);
  const [deleteId, setdeleteId] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    loadCraigslistList();
  }, []);

  const loadCraigslistList = async () => {
    try {
      fetch(baseURL + "api/get-craigslist")
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setCraigslistList(data);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const doOpenDelete = (prompt_id) => {
    setdeleteId(prompt_id);
    setisOpenDelete(true);
  };

  const doDelete = async () => {
    fetch(`${process.env.REACT_APP_BASE_URL}api/craigslist-delete/` + deleteId, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        setisOpenDelete(false);
        loadCraigslistList();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const doStartPosting = async (post_id, startStop) => {
    var urlAdd = startStop === 1 ? "craigslist-start-post/" : "craigslist-stop-post/";

    fetch(`${process.env.REACT_APP_BASE_URL}api/` + urlAdd + post_id, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        loadCraigslistList();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Needs to be added to Util
  const relativeTimePeriods = [
    [31536000, "year"],
    [2419200, "month"],
    [604800, "week"],
    [86400, "day"],
    [3600, "hour"],
    [60, "min"],
    [1, "sec"],
  ];

  function relativeTime(date) {
    if (!(date instanceof Date)) date = new Date(date);
    const seconds = (new Date() - date) / 1000;
    for (let [secondsPer, name] of relativeTimePeriods) {
      if (seconds >= secondsPer) {
        const amount = Math.floor(seconds / secondsPer);
        return `${amount} ${name}${amount ? "s" : ""} ago`;
      }
    }
    return "Just now";
  }

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      <div className="top_buttons">
        <button
          className="big_button mr20"
          id="showGroupPopup"
          type="button"
          onClick={() => {
            setIsPopupOpen(true);
          }}
        >
          Add New Post
        </button>
      </div>

      <table id="dataTable" className="agent_table aip_table">
        <thead>
          <tr>
            <th style={{ width: "200px" }}>Image</th>
            <th style={{ width: "200px" }}>Post Name</th>
            <th>Post Description</th>
            <th style={{ width: "200px", textAlign: "center" }}>Status</th>
            <th style={{ width: "200px", textAlign: "center" }}>Posting</th>
            <th style={{ width: "100px" }}></th>
          </tr>
        </thead>
        <tbody>
          {craigslistList &&
            craigslistList.map((onePost, index) => (
              <tr key={index}>
                <td>{onePost.post_image ? <img src={process.env.REACT_APP_IMAGE_URL + "uploads/" + onePost.post_image} alt="img" /> : ""}</td>
                <td>
                  <b>{onePost.post_name}</b>
                  <br />
                  <br />
                  Created: {relativeTime(onePost.post_date)}
                </td>
                <td>{onePost.post_description}</td>
                <td>
                  <b>{onePost.posting === 1 ? (onePost.posting_now === 1 ? "Posting Now" : "Posting scheduled") : "Paused:"}</b>
                  <br />
                  <br />
                  {onePost.posting === 1 ? "Started:" : "Paused:"} {onePost.post_start_date ? relativeTime(onePost.post_start_date) : "NOT"}
                  {onePost.post_last_post ? (
                    <>
                      <br />
                      {"Last posted: " + relativeTime(onePost.post_last_post)}
                    </>
                  ) : (
                    ""
                  )}
                  {onePost.posting === 0 ? (
                    <>
                      <br />
                      <button
                        className="delete_button delete_button_wide"
                        type="button"
                        onClick={() => {
                          doStartPosting(onePost.id, 1);
                        }}
                      >
                        Start Posting
                      </button>
                    </>
                  ) : (
                    <>
                      <br />
                      <button
                        className="delete_button delete_button_wide"
                        type="button"
                        onClick={() => {
                          doStartPosting(onePost.id, 0);
                        }}
                      >
                        Pause Posting
                      </button>
                    </>
                  )}
                </td>
                <td style={{ textAlign: "center" }}>
                  {onePost.posted} / {onePost.pending}
                </td>
                <td style={{ textAlign: "center" }}>
                  <button
                    className="delete_button"
                    type="button"
                    onClick={() => {
                      doOpenDelete(onePost.id);
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <Areyousure
        isOpen={isOpenDelete}
        onNo={() => {
          setisOpenDelete(false);
        }}
        onYes={doDelete}
        popupTitle="Are you sure you want to remove this post?"
      />

      {isPopupOpen && (
        <CreateCraigslistPost
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          loadCraigslistList={loadCraigslistList}
        />
      )}
    </div>
  );
};

export default Craigslist;
