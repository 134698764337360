import React, { useEffect, useState } from "react";
import "./Home.css";
import CreateHomeLink from "./CreateHomeLink";
import Areyousure from "../../components/popups/Areyousure";
import Spinner from "../../components/spinner/Spinner";
const Home = () => {
  const [homeLinks, setHomeLinks] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [searchStatus, setSearchStatus] = useState("");
  const [isOpenDelete, setisOpenDelete] = useState(false);
  const [deleteId, setdeleteId] = useState(null);

  useEffect(() => {
    fetchHomeLinks();
    // Call fetchData initially and then every 10 seconds (10000 milliseconds)
    const intervalId = setInterval(fetchHomeLinks, 10000);

    // Clean up the interval to avoid memory leaks
    return () => clearInterval(intervalId);
  }, []);

  // get list of all links
  const fetchHomeLinks = async () => {
    try {
      let URL = `${process.env.REACT_APP_BASE_URL}api/get-properties-stats`;

      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setHomeLinks(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const sendPropertiesLoad = async (urlSend) => {
    let data = { url: urlSend };
    try {
      let URL = `${process.env.REACT_APP_BASE_URL}api/get-properties`;

      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      if (result.success) {
        setSearchStatus(result.success);
      } else {
        setSearchStatus(result.error);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const relativeTimePeriods = [
    [31536000, "year"],
    [2419200, "month"],
    [604800, "week"],
    [86400, "day"],
    [3600, "hour"],
    [60, "min"],
    [1, "sec"],
  ];

  function relativeTime(date) {
    if (!(date instanceof Date)) date = new Date(date);
    const seconds = (new Date() - date) / 1000;
    for (let [secondsPer, name] of relativeTimePeriods) {
      if (seconds >= secondsPer) {
        const amount = Math.floor(seconds / secondsPer);
        return `${amount} ${name}${amount ? "s" : ""} ago`;
      }
    }
    return "Just now";
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleContinueClick = async () => {
    if (!formData.input_link) {
      setSearchStatus("Provide a valid homes link");
    } else {
      setIsPopupOpen(true);
    }
  };

  const doOpenDelete = (link_id) => {
    setdeleteId(link_id);
    setisOpenDelete(true);
  };

  const doDelete = async () => {
    let data = {
      id: deleteId,
    };
    fetch(`${process.env.REACT_APP_BASE_URL}api/delete-link`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setisOpenDelete(false);
        fetchHomeLinks();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      {isPopupOpen && (
        <CreateHomeLink
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          fetchHomeLinks={fetchHomeLinks}
          input_link={formData.input_link}
          setSearchStatus={setSearchStatus}
        />
      )}

      {isOpenDelete && (
        <Areyousure
          isOpen={isOpenDelete}
          onNo={() => {
            setisOpenDelete(false);
          }}
          onYes={doDelete}
          popupTitle="Are you sure you want to remove this Link?"
        />
      )}

      <div className="top_search">
        <div className="top_search_content">
          <input type="text" id="input_link" className="input_link" name="input_link" value={formData.input_link || ""} onChange={handleChange} placeholder="Enter homes search link..." />
          <div className="search_icon" onClick={handleContinueClick}>
            Continue
          </div>
        </div>
        <div className="top_search_content" id="search_status">
          {searchStatus}
        </div>
      </div>
      <div className="active_searches">
        {homeLinks &&
          homeLinks.map((item) => (
            <div key={item.id} className="active_search">
              <div className="link_title">{item.title ? item.title : "No Title..."}</div>
              <div className="as_link">{item.link}</div>
              <div className="as_total">Loaded Pages: {(item.loaded_pages ? item.loaded_pages : 0) + " / " + (item.total_pages ? item.total_pages : 0)}</div>
              <div className="as_total">Loaded Properties: {(item.loaded_properties ? item.loaded_properties : 0) + " / " + (item.total_properties ? item.total_properties : 0)}</div>
              <div className="as_total">Last Scan: {relativeTime(item.last_update * 1000)}</div>
              <div className="load_properties" onClick={() => sendPropertiesLoad(item.link)}>
                Load Properties
              </div>{" "}
              {/* All Properties sheet */}
              <a href={`https://docs.google.com/spreadsheets/d/${item.sheet_id}/edit#gid=0`} target="_blank" rel="noreferrer">
                <div className="load_properties mtb20">All Prop Sheet</div>
              </a>
              {/* For Sale sheet */}
              <a href={`https://docs.google.com/spreadsheets/d/${item.for_sale_sheet_id}/edit#gid=0`} target="_blank" rel="noreferrer">
                <div className="load_properties mtb20">For sale Sheet</div>
              </a>
              {/* Sold sheet */}
              <a href={`https://docs.google.com/spreadsheets/d/${item.sold_sheet_id}/edit#gid=0`} target="_blank" rel="noreferrer">
                <div className="load_properties mtb20">Sold Sheet</div>
              </a>
              {/* Pending sheet */}
              <a href={`https://docs.google.com/spreadsheets/d/${item.pending_sheet_id}/edit#gid=0`} target="_blank" rel="noreferrer">
                <div className="load_properties mtb20">Pending Sheet</div>
              </a>
              {/* Back on market sheet */}
              <a href={`https://docs.google.com/spreadsheets/d/${item.back_sheet_id}/edit#gid=0`} target="_blank" rel="noreferrer">
                <div className="load_properties mtb20">Back on market Sheet</div>
              </a>
              {/* Off market sheet */}
              <a href={`https://docs.google.com/spreadsheets/d/${item.off_sheet_id}/edit#gid=0`} target="_blank" rel="noreferrer">
                <div className="load_properties mtb20">Off market Sheet</div>
              </a>
              <div
                className="as_delete"
                onClick={() => {
                  doOpenDelete(item.id);
                }}
              >
                &times;
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Home;
