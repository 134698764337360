import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./components/header/Header";

import Facebookgroups from "./pages/facebook/facebookgroups/Facebookgroups";
import Facebookposts from "./pages/facebook/facebookposts/Facebookposts";
import Facebookaccounts from "./pages/facebook/facebookaccounts/Facebookaccounts";
import Facebookmessages from "./pages/facebook/facebookmessages/Facebookmessages";
import SearchFbGroups from "./pages/facebook/searchfbgroups/SearchFbGroups";
import FacebookLogs from "./pages/facebook/facebooklogs/FacebookLogs";
import Home from "./pages/home/Home";
import OwnerLinks from "./pages/ownerlinks/OwnerLinks";
import Calculation from "./pages/calculation/Calculation";
import AgentsList from "./pages/agentslist/AgentsList";
import Aiprompts from "./pages/aiprompts/Aiprompts";
import Craigslist from "./pages/craigslist/Craigslist";
import Duval from "./pages/duval/Duval";
import MapSheets from "./pages/mapsheets/MapSheets";
import HomeAddressSheet from "./pages/homeaddresssheets/HomeAddressSheet";
import Spintext from "./pages/spintext/Spintext";
import FacebookImport from "./pages/facebook/facebookimport/FacebookImport";

const NoHeaderLayout = ({ children }) => {
  return <>{children}</>;
};

const HeaderLayout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
    </>
  );
};
function App() {
  return (
    <Router>
      <div className="App">
        {/* <Header /> */}
        <div>
          <Routes>
            <Route
              path="/"
              element={
                <HeaderLayout>
                  <Home />
                </HeaderLayout>
              }
            />
            <Route
              path="/owner-links"
              element={
                <HeaderLayout>
                  <OwnerLinks />
                </HeaderLayout>
              }
            />
            <Route
              path="/calculations"
              element={
                <HeaderLayout>
                  <Calculation />
                </HeaderLayout>
              }
            />
            <Route
              path="/agents-list"
              element={
                <HeaderLayout>
                  <AgentsList />
                </HeaderLayout>
              }
            />
            <Route
              path="/map-sheets"
              element={
                <HeaderLayout>
                  <MapSheets />
                </HeaderLayout>
              }
            />
            <Route
              path="/facebook-groups"
              element={
                <HeaderLayout>
                  <Facebookgroups />
                </HeaderLayout>
              }
            />
            <Route
              path="/facebook-posts"
              element={
                <HeaderLayout>
                  <Facebookposts />
                </HeaderLayout>
              }
            />
            <Route
              path="/facebook-accounts"
              element={
                <HeaderLayout>
                  <Facebookaccounts />
                </HeaderLayout>
              }
            />
            <Route
              path="/facebook-messages"
              element={
                <HeaderLayout>
                  <Facebookmessages />
                </HeaderLayout>
              }
            />
            <Route
              path="/search-fb-groups"
              element={
                <HeaderLayout>
                  <SearchFbGroups />
                </HeaderLayout>
              }
            />
            <Route
              path="/facebook-logs"
              element={
                <HeaderLayout>
                  <FacebookLogs />
                </HeaderLayout>
              }
            />
            <Route
              path="/facebook-import"
              element={
                <HeaderLayout>
                  <FacebookImport />
                </HeaderLayout>
              }
            />
            <Route
              path="/address-sheets"
              element={
                <HeaderLayout>
                  <HomeAddressSheet />
                </HeaderLayout>
              }
            />
            <Route
              path="/ai-prompts"
              element={
                <HeaderLayout>
                  <Aiprompts />
                </HeaderLayout>
              }
            />
            <Route
              path="/craigslist"
              element={
                <HeaderLayout>
                  <Craigslist />
                </HeaderLayout>
              }
            /> 
            <Route
              path="/duval"
              element={
                <HeaderLayout>
                  <Duval />
                </HeaderLayout>
              }
            /> 
            <Route
              path="/spintext"
              element={
                <NoHeaderLayout>
                  <Spintext />
                </NoHeaderLayout>
              }
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
