import React, { useState } from "react";

const ReplaceAccount = ({ isOpen, onClose, currentAccount, fetchaccountsList }) => {
  const [formData, setFormData] = useState({});
  const [apiResponse, setApiResponse] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const replaceAccountAPI = (e) => {
    e.preventDefault();
    try {
      // Disable the button
      setButtonClicked(true);
      let data = {
        account_id: currentAccount.id,
        name: formData.account_name,
        email: formData.account_email,
        password: formData.account_password,
        proxy: formData.account_proxy ? formData.account_proxy : "",
        user_info: formData.account_info ? formData.account_info : "",
      };

      fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/replace_account`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setApiResponse(data.success);
            onClose();
            fetchaccountsList();
            // close popup and refresh the list
          } else {
            setApiResponse(data.error);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors
          setApiResponse(error.messag);
        });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Enable the button after 3 seconds
      setTimeout(() => {
        setButtonClicked(false);
      }, 2000);
    }
  };

  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-500">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Replace Account "{currentAccount.name}"</h2>

        <form onSubmit={replaceAccountAPI}>
          <div>
            <input type="text" id="account_name" className="input_field" name="account_name" placeholder="Account Name" value={formData.account_name || ""} onChange={handleChange} />
          </div>
          <div>
            <input type="text" id="account_email" className="input_field" name="account_email" placeholder="Account Email" value={formData.account_email || ""} onChange={handleChange} />
          </div>
          <div>
            <input type="text" id="account_password" className="input_field" name="account_password" placeholder="Account Password" value={formData.account_password || ""} onChange={handleChange} />
          </div>
          <div>
            <input type="text" id="account_proxy" className="input_field" name="account_proxy" placeholder="Account Proxy" value={formData.account_proxy || ""} onChange={handleChange} />
          </div>
          <div>
            <input type="text" id="account_info" className="input_field" name="account_info" placeholder="Account Information" value={formData.account_info || ""} onChange={handleChange} />
          </div>

          <button type="submit" className={`big_button ${buttonClicked ? "disabledButton" : ""}`} disabled={buttonClicked}>
            Replace
          </button>

          <p>{apiResponse}</p>
        </form>
      </div>
    </div>
  );
};

export default ReplaceAccount;
