import React, { useEffect, useState } from "react";

const SearchNewGroups = ({ isOpen, onClose, fetchgroupsList, setSearchApiResponse, editGroup }) => {
  const [formData, setFormData] = useState({});
  const [apiResponse, setApiResponse] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (editGroup != null) {
      console.log(editGroup);
      setFormData((prevData) => ({
        ...prevData,
        editId: editGroup.id,
        searchQuery: editGroup.search_query,
        searchPrompt: editGroup.search_prompt,
      }));
    }
  }, [editGroup]);

  const searchFbGroupsAPI = (e) => {
    e.preventDefault();

    fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/fetch_groups_from_fb`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setFormData({});
          // close popup and refresh the list
          onClose();
          fetchgroupsList();
          let response = data.success + " for query " + formData.searchQuery + " ...";
          setSearchApiResponse(response);
        } else {
          setApiResponse(data.error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors
        setApiResponse(error.messag);
      });
  };
  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-500">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Search New Facebook Groups</h2>

        <form onSubmit={searchFbGroupsAPI}>
          <div>
            <input type="text" className="input_field" name="searchQuery" placeholder="Search Query..." value={formData.searchQuery || ""} onChange={handleChange} />
          </div>

          <div>
            <textarea className="input_field" name="searchPrompt" placeholder="Search prompt..." value={formData.searchPrompt || ""} onChange={handleChange}></textarea>
          </div>

          <button type="submit" className="big_button">
            Submit
          </button>

          <p>{apiResponse}</p>
        </form>
      </div>
    </div>
  );
};

export default SearchNewGroups;
