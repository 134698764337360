import React, { useState, useEffect } from "react";
import "./Calculation.css";
import Spinner from "../../components/spinner/Spinner";
function Calculation() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [exitPercentage, setExitPercentage] = useState("");
  const [profit, setProfit] = useState("");
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    loadCalculation();
  }, []);

  const loadCalculation = async () => {
    try {
      let URL = `${process.env.REACT_APP_BASE_URL}api/get-offer-calculation`;

      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      if (result && result.prices) {
        setPrices(result.prices);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const addPrices = () => {
    let URL = `${process.env.REACT_APP_BASE_URL}api/save-new-offer-prices`;
    fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ min: minPrice, max: maxPrice, exit: exitPercentage, profit }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          loadCalculation();
          setMinPrice("");
          setMaxPrice("");
          setExitPercentage("");
          setProfit("");
        } else {
          setError(data.error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError(error);
      });
  };

  const deleteOffer = (id) => {
    fetch(process.env.REACT_APP_BASE_URL + "api/delete-offer-calculation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    })
      .then((response) => response.json())
      .then((data) => {
        loadCalculation();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (loading) {
    // return <p>Loading...</p>;
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      <div className="top_search"></div>

      <table id="dataTable">
        <thead>
          <tr>
            <th>Minimum Price</th>
            <th>Maximum Price</th>
            <th>Exit Percentage</th>
            <th>Profit</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {prices &&
            prices.map((res, index) => (
              <tr key={index}>
                <td>{res.from_price}</td>
                <td>{res.to_price}</td>
                <td>{res.exit_percentage}</td>
                <td>{res.profit}</td>
                <td style={{ textAlign: "left" }}>
                  <button className="delete_button" onClick={() => deleteOffer(res.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="inputs_container">
        <input className="calc_price_input" type="number" value={minPrice} onChange={(e) => setMinPrice(e.target.value)} placeholder="Minimum Price" />
        <input className="calc_price_input" type="number" value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} placeholder="Maximum Price" />
        <input className="calc_price_input" type="number" value={exitPercentage} onChange={(e) => setExitPercentage(e.target.value)} placeholder="Exit Percentage" />
        <input className="calc_price_input" type="number" value={profit} onChange={(e) => setProfit(e.target.value)} placeholder="Profit" />
        <button className="calc_add_btn" onClick={addPrices}>
          Add
        </button>
      </div>
    </div>
  );
}

export default Calculation;
