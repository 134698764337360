import React, { useEffect, useState } from "react";

const CreateAccount = ({ isOpen, onClose, fetchaccountsList, accountInfo }) => {
  const [formData, setFormData] = useState({});
  const [apiResponse, setApiResponse] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (accountInfo != null) {
      console.log(accountInfo);
      setFormData((prevData) => ({
        ...prevData,
        account_name: accountInfo.name,
        account_email: accountInfo.email,
        account_password: accountInfo.password,
        account_proxy: accountInfo.proxy ? accountInfo.proxy : "",
        account_info: accountInfo.user_info ? accountInfo.user_info : "",
      }));
    }
  }, [accountInfo]);

  const createAccountAPI = (e) => {
    e.preventDefault();
    try {
      // Disable the button
      setButtonClicked(true);
      let data = {
        name: formData.account_name,
        email: formData.account_email,
        password: formData.account_password,
        proxy: formData.account_proxy ? formData.account_proxy : "",
        user_info: formData.account_info ? formData.account_info : "",
      };

      var method = accountInfo == null ? "POST" : "PUT";
      var addLink = accountInfo == null ? "" : "/" + accountInfo.id;

      fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/accounts` + addLink, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            onClose();
            fetchaccountsList();
            // close popup and refresh the list
          } else {
            setApiResponse(data.error);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors
          setApiResponse(error.messag);
        });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Enable the button after 3 seconds
      setTimeout(() => {
        setButtonClicked(false);
      }, 2000);
    }
  };

  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-500">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Add New Account</h2>

        <form onSubmit={createAccountAPI}>
          <div>
            <input type="text" id="account_name" className="input_field" name="account_name" placeholder="Account Name" value={formData.account_name || ""} onChange={handleChange} />
          </div>
          <div>
            <input type="text" id="account_email" className="input_field" name="account_email" placeholder="Account Email" value={formData.account_email || ""} onChange={handleChange} />
          </div>
          <div>
            <input type="text" id="account_password" className="input_field" name="account_password" placeholder="Account Password" value={formData.account_password || ""} onChange={handleChange} />
          </div>
          <div>
            <input type="text" id="account_proxy" className="input_field" name="account_proxy" placeholder="Account Proxy" value={formData.account_proxy || ""} onChange={handleChange} />
          </div>
          <div>
            <input type="text" id="account_info" className="input_field" name="account_info" placeholder="Account Information" value={formData.account_info || ""} onChange={handleChange} />
          </div>

          <button type="submit" className={`big_button ${buttonClicked ? "disabledButton" : ""}`} disabled={buttonClicked}>
            {accountInfo == null ? "Create" : "Update"}
          </button>

          <p>{apiResponse}</p>
        </form>
      </div>
    </div>
  );
};

export default CreateAccount;
