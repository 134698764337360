import React, { useEffect, useState } from "react";
import "./Facebookgroups.css";
const SingleFbGroup = ({ folder_id, singleFbGroup, onOpenDelete, onOpenQuestions, onOpenUpdate, foldersList, onCheckboxChange, selected }) => {
  const [accounts, setAccounts] = useState(0);
  useEffect(() => {
    getFbGroupStats();
  }, []);
  const getFbGroupStats = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/get_fb_group_stats`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      if (result && result.length > 0) {
        let currentGroupStat = result.find((obj) => obj.group_id === singleFbGroup.id);
        if (currentGroupStat) {
          setAccounts(currentGroupStat.added_accounts);
        }
      }
    } catch (error) {
      //  setError(error);
    } finally {
      //  setLoading(false);
    }
  };

  const handleUpdateClick = async () => {
    let folders = [];

    // add folders to group to use default selected folders
    if (foldersList && foldersList.length > 0) {
      foldersList.forEach((singleFolder) => {
        if (singleFolder.groups.some((obj) => obj.id === singleFbGroup.id)) {
          folders.push({ label: singleFolder.folder_name, value: singleFolder.folder_id });
        }
      });
    }
    singleFbGroup.folders = folders;
    onOpenUpdate(singleFbGroup);
  };

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    onCheckboxChange(singleFbGroup.id, checked);
  };
  return (
    <div className="group_parent_div">
      <div className="group_checkbox">
        <input type="checkbox" id={singleFbGroup.id} name="selected_checkbox" checked={selected} onChange={handleCheckboxChange} className="selected_checkbox" />
      </div>
      <img className="img_round" src={singleFbGroup.image} width="120" height="120" alt=" " />
      <div className="active_list">
        <div className="group_name_div">{singleFbGroup.group_name ? singleFbGroup.group_name : "Loading..."}</div>

        <div className="group_stats">
          <div>Accounts:</div>
          <div>
            <a href={"facebook-accounts?group_id=" + singleFbGroup.id}>
              <b>{accounts}</b>
            </a>
          </div>
          <div>Posts:</div>
          <div>
            <a href={"facebook-accounts?group_id=" + singleFbGroup.id}>
              <b>{singleFbGroup.total_posts}</b>
            </a>
          </div>
        </div>

        <div className="buttons_grid">
          {singleFbGroup.has_questions > 0 ? (
            <div
              className="small_button"
              onClick={() => {
                onOpenQuestions(singleFbGroup.id);
              }}
            >
              Questions
            </div>
          ) : (
            <div></div>
          )}
          <div
            className="small_button"
            onClick={() => {
              handleUpdateClick(singleFbGroup);
            }}
          >
            Update
          </div>
          <div
            className="small_button button_orange"
            onClick={() => {
              onOpenDelete(folder_id, singleFbGroup.id);
            }}
          >
            Delete
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleFbGroup;
