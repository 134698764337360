import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import Spinner from "../../../components/spinner/Spinner";

const JoinFbGroup = ({ isOpen, onClose, fetchgroupsList, group_id }) => {
  const [formData, setFormData] = useState({ group_id: group_id });
  const [foldersList, setFoldersList] = useState([]);
  const [apiResponse, setApiResponse] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFolders, setSelectedFolders] = useState([]);

  useEffect(() => {
    fetchFoldersList();
  }, []);

  const fetchFoldersList = async () => {
    try {
      let URL = `${process.env.REACT_APP_BASE_URL}api/facebook/folders`;

      const response = await fetch(URL);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();

      setFoldersList(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const transformedArray = foldersList.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  const handleCreateGroup = (e) => {
    e.preventDefault();
    let selectedFolderIDs = selectedFolders.map((item) => item.value);
    let data = {
      group_id: formData.group_id,
      selectedFolders: selectedFolderIDs,
    };
    if (data.group_id && data.selectedFolders && data.selectedFolders.length > 0) {
      createGroupAPI(data);
    } else {
      setApiResponse("Select at least one folder!");
    }
  };

  const createGroupAPI = (data) => {
    fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/add_searched_gorup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          onClose();
          setSelectedFolders([]);
          setTimeout(() => {
            fetchgroupsList();
            setFormData({});
          }, 2000); // close popup and refresh the list
        } else {
          setApiResponse(data.error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors
        setApiResponse(error.messag);
      });
  };

  if (loading) {
    // return <p>Loading...</p>;
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-500">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Add Selected Group to Folder</h2>

        <form onSubmit={handleCreateGroup}>
          {/* <div>
            <input type="text" id="group_link" className="input_field" name="group_link" placeholder="Group Link" value={formData.group_link || ""} onChange={handleChange} />
          </div> */}
          <div className="multi_select">{transformedArray && transformedArray.length > 0 && <MultiSelect options={transformedArray} value={selectedFolders} onChange={setSelectedFolders} labelledBy="Select" />}</div>

          <button type="submit" className="big_button">
            Submit
          </button>

          <p>{apiResponse}</p>
        </form>
      </div>
    </div>
  );
};

export default JoinFbGroup;
