import React, { useEffect, useState } from "react";

const Createaiprompt = ({ isOpen, onClose, loadPromptsList, promptInfo }) => {
  const [formData, setFormData] = useState({});
  const [apiResponse, setApiResponse] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (promptInfo != null) {
      console.log(promptInfo);
      setFormData((prevData) => ({
        ...prevData,
        prompt_name: promptInfo.prompt_name,
        prompt_text: promptInfo.prompt_text,
        update_field: promptInfo.update_field,
      }));
    }
  }, [promptInfo]);

  const createPromptAPI = (e) => {
    e.preventDefault();
    try {
      // Disable the button
      setButtonClicked(true);
      let data = {
        prompt_name: formData.prompt_name,
        prompt_text: formData.prompt_text,
        update_field: formData.update_field,
      };

      var method = promptInfo == null ? "POST" : "PUT";
      var addLink = promptInfo == null ? "" : "/" + promptInfo.id;

      fetch(`${process.env.REACT_APP_BASE_URL}api/ai-prompt-add` + addLink, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            onClose();
            loadPromptsList();
            // close popup and refresh the list
          } else {
            setApiResponse(data.error);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors
          setApiResponse(error.messag);
        });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Enable the button after 3 seconds
      setTimeout(() => {
        setButtonClicked(false);
      }, 2000);
    }
  };

  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-500">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Add New Prompt</h2>

        <form onSubmit={createPromptAPI}>
          <div>
            <input type="text" id="prompt_name" className="input_field" name="prompt_name" placeholder="Prompt Name" value={formData.prompt_name || ""} onChange={handleChange} />
          </div>
          <div>
            <textarea rows="4" id="prompt_text" className="input_field" name="prompt_text" placeholder="Prompt Text" value={formData.prompt_text || ""} onChange={handleChange} />
          </div>
          <div>
            <input type="text" id="update_field" className="input_field" name="update_field" placeholder="Update field name. (ex. property_quick_flip)" value={formData.update_field || ""} onChange={handleChange} />
          </div>

          <button type="submit" className={`big_button ${buttonClicked ? "disabledButton" : ""}`} disabled={buttonClicked}>
            {promptInfo == null ? "Create" : "Update"}
          </button>

          <p>{apiResponse}</p>
        </form>
      </div>
    </div>
  );
};

export default Createaiprompt;
