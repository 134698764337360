import React, { useEffect, useState } from "react";
import "./Searchfbgroups.css";
import SingleSearchedGroup from "./SingleSearchedGroup";
import SearchNewGroups from "./SearchNewGroups";
import JoinFbGroup from "./JoinFbGroup";
import Spinner from "../../../components/spinner/Spinner";

const SearchFbGroups = () => {
  const [groupsList, setGroupsList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isJoinPopupOpen, setIsJoinPopupOpen] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  const [selectedGroup, setEditGroup] = useState(null);

  const [searchApiResponse, setSearchApiResponse] = useState("");

  useEffect(() => {
    fetchgroupsList();
  }, []);

  const fetchgroupsList = async () => {
    try {
      let URL = `${process.env.REACT_APP_BASE_URL}api/facebook/fetch_groups_from_db`;

      const response = await fetch(URL);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setGroupsList(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const doDeleteGroup = async (id) => {
    try {
      let URL = `${process.env.REACT_APP_BASE_URL}api/facebook/deletesearch/` + id;
      await fetch(URL);
      fetchgroupsList();
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const relativeTimePeriods = [
    [31536000, "year"],
    [2419200, "month"],
    [604800, "week"],
    [86400, "day"],
    [3600, "hour"],
    [60, "min"],
    [1, "sec"],
  ];

  function relativeTime(date) {
    if (!(date instanceof Date)) date = new Date(date);
    const seconds = (new Date() - date) / 1000;
    for (let [secondsPer, name] of relativeTimePeriods) {
      if (seconds >= secondsPer) {
        const amount = Math.floor(seconds / secondsPer);
        return `${amount} ${name}${amount ? "s" : ""} ago`;
      }
    }
    return "Just now";
  }

  if (loading) {
    // return <p>Loading...</p>;
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <div>
      <div className="top_buttons">
        <button
          className="big_button mr20"
          id="showGroupPopup"
          type="button"
          onClick={() => {
            setEditGroup(null);
            setIsPopupOpen(true);
          }}
        >
          Load New Groups
        </button>
        <div className="api_response">{searchApiResponse}</div>
      </div>

      {isPopupOpen && (
        <SearchNewGroups
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          fetchgroupsList={fetchgroupsList}
          setSearchApiResponse={setSearchApiResponse}
          editGroup={selectedGroup}
        />
      )}
      {isJoinPopupOpen && (
        <JoinFbGroup
          isOpen={isJoinPopupOpen}
          onClose={() => {
            setIsJoinPopupOpen(false);
          }}
          fetchgroupsList={fetchgroupsList}
          group_id={selectedGroupId}
        />
      )}
      <div className="active_list_container">
        <div className="group_active_lists">
          <div className="one_query one_query_title">
            <div className="search_query">Search</div>
            <div className="search_query">Prompt</div>
            <div className="search_count">Found Groups</div>
            <div className="search_count">Good Groups</div>
            <div className="search_count">Last Scan</div>
            <div className="search_count"></div>
          </div>

          {groupsList.map((oneGroup) => (
            <div key={oneGroup.id} className="one_query">
              <div className="search_query">{oneGroup.search_query}</div>
              <div className="search_query">{oneGroup.search_prompt}</div>
              <div className="search_count">{oneGroup.groups_found}</div>
              <div className="search_count">{oneGroup.groups_valid}</div>
              <div className="search_count">{relativeTime(oneGroup.last_scan)}</div>
              <div className="search_count">
                <button
                  className="big_button mr20"
                  type="button"
                  onClick={() => {
                    setEditGroup(oneGroup);
                    setIsPopupOpen(true);
                  }}
                >
                  Edit
                </button>
                <br />
                <br />
                <button
                  className="big_button mr20 button_orange"
                  type="button"
                  onClick={() => {
                    doDeleteGroup(oneGroup.id);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchFbGroups;
