import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

const Header = () => {
  return (
    <div>
      <nav className="navbar">
        {/* <!-- LOGO --> */}
        <div className="logo">Real Estate</div>
        {/* <!-- NAVIGATION MENU --> */}
        <ul className="nav-links">
          {/* <!-- USING CHECKBOX HACK --> */}
          {/* <!-- <input type="checkbox" id="checkbox_toggle" /> --> */}

          {/* <!-- NAVIGATION MENUS --> */}
          <div className="menu">
            {/* <!-- <li><a id="homeLink" onclick="navigateHome()">Home</a></li> --> */}

            <li>
              <Link to="/">Agent Links</Link>
            </li>
            <li>
              <Link to="/owner-links">Owner Links</Link>
            </li>
            <li className="facebook">
              <Link to="/facebook-accounts">Facebook</Link>

              <ul className="dropdown">
                <li>
                  <Link to="/facebook-accounts">Facebook Accounts</Link>
                </li>
                <li>
                  <Link to="/facebook-groups">Facebook Groups</Link>
                </li>
                <li>
                  <Link to="/facebook-posts">Facebook Posts</Link>
                </li>
                <li>
                  <Link to="/facebook-import">Facebook Import</Link>
                </li>
                <li>
                  <Link to="/facebook-logs">Facebook Logs</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/map-sheets">Transfer Sheet Data</Link>
            </li>
            <li>
              <Link to="/agents-list">Agents List</Link>
            </li>
            <li>
              <Link to="/calculations">Calculations</Link>
            </li>
            <li>
              <Link to="/address-sheets">Address Sheets</Link>
            </li>
            <li>
              <Link to="/craigslist">Cragislist</Link>
            </li>
            <li>
              <Link to="/duval">Duval</Link>
            </li>
            <li>
              <Link to="/ai-prompts">AI Prompts</Link>
            </li>
          </div>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
