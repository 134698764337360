import React, { useState, useEffect } from "react";
import "./AgentsList.css";
import Spinner from "../../components/spinner/Spinner";
const AgentsList = () => {
  let baseURL = process.env.REACT_APP_BASE_URL;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [agentList, setAgentList] = useState([]);
  const [newAgentName, setNewAgentName] = useState("");
  const [newLicenseNumber, setNewLicenseNumber] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [addLicenseMessage, setAddLicenseMessage] = useState("");

  useEffect(() => {
    loadAgentsEmails();
  }, []);

  const loadAgentsEmails = async () => {
    try {
      fetch(baseURL + "api/get-agents-list")
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setAgentList(data);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const addBulkEmails = () => {
    setApiResponse("Updating Emails...");
    try {
      const table = document.getElementById("dataTable");
      const data = [];

      for (let i = 1; i < table.rows.length; i++) {
        const row = table.rows[i];
        const rowData = {};

        rowData.agent_name = row.cells[0].textContent;
        rowData.companyName = row.cells[1].textContent;
        rowData.license = row.cells[2].textContent;
        rowData.email = row.cells[3].querySelector("input").value;

        data.push(rowData);
      }

      fetch(baseURL + "api/save-agents-emails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setApiResponse(data.success);
            loadAgentsEmails();
          } else {
            setApiResponse(data.error);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setError(error);
        });
    } catch (error) {
      setError(error);
    }
  };

  const addNewLicenseEmail = () => {
    if (newAgentName && newLicenseNumber && newEmail) {
      try {
        fetch(baseURL + "api/save-new-email-license", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ new_agent_name: newAgentName, new_license_number: newLicenseNumber, new_email: newEmail }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              setAddLicenseMessage(data.success);
              loadAgentsEmails();
              setNewAgentName("");
              setNewLicenseNumber("");
              setNewEmail("");
            } else {
              setAddLicenseMessage(data.error);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setError(error);
          });
      } catch (error) {
        setError(error);
      }
    } else {
      setAddLicenseMessage("Name, License and Email are required.");
    }
  };

  if (loading) {
    // return <p>Loading...</p>;
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <div>
      <div className="top_search"></div>

      <table id="dataTable" className="agent_table">
        <thead>
          <tr>
            <th>Agent Full Name</th>
            <th>Agency Name</th>
            <th>License #</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {agentList &&
            agentList.map((agent, index) => (
              <tr key={index}>
                <td>{agent.agent_name}</td>
                <td>{agent.companyName}</td>
                <td>{agent.license}</td>
                <td>
                  <input className="email_input" type="email" defaultValue={agent.email} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <button className="add_btn" type="button" onClick={addBulkEmails}>
        Save Emails
      </button>
      <div>{apiResponse}</div>
      <div className="agent_inputs_container">
        <input className="price_input" type="text" value={newAgentName} onChange={(e) => setNewAgentName(e.target.value)} placeholder="Agent Name" />
        <input className="price_input" type="text" value={newLicenseNumber} onChange={(e) => setNewLicenseNumber(e.target.value)} placeholder="License Number" />
        <input className="price_input" type="email" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} placeholder="Email" />
        <div>
          <button className="add_btn" onClick={addNewLicenseEmail}>
            Add License
          </button>
          <p>{addLicenseMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default AgentsList;
