import React, { useState } from "react";
import "./Facebookgroups.css";

const CreateFolder = ({ isOpen, onClose, fetchgroupsList }) => {
  const [formData, setFormData] = useState({});
  const [apiResponse, setApiResponse] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const createFolderAPI = (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/folders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("resp", data);
        if (data.success) {
          setFormData({});
          onClose();
          fetchgroupsList();
          // close popup and refresh the list
        } else {
          setApiResponse(data.error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors
        setApiResponse(error.messag);
      });
  };

  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-500">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Add New Folder</h2>

        <form onSubmit={createFolderAPI}>
          <div>
            <input type="text" id="folder_name" className="input_field" name="name" placeholder="Folder Name" value={formData.name || ""} onChange={handleChange} />
          </div>

          <button type="submit" className="big_button">
            Add New Folder
          </button>

          <p>{apiResponse}</p>
        </form>
      </div>
    </div>
  );
};

export default CreateFolder;
