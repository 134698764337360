import React, { useState } from "react";

import "./Facebookgroups.css";
import { MultiSelect } from "react-multi-select-component";

const UpdateGroup = ({ isOpen, onClose, fetchgroupsList, foldersList, groupToUpdate }) => {
  const [formData, setFormData] = useState(groupToUpdate);
  const [apiResponse, setApiResponse] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);

  const transformedArray = foldersList.map((item) => {
    return {
      label: item.folder_name,
      value: item.folder_id,
    };
  });

  // Set default selected options
  // const defaultSelectedOptions = [
  //   { label: "Michigan Groups", value: 12 },
  // ];
  const defaultSelectedOptions = groupToUpdate?.folders || [];

  const [selectedFolders, setSelectedFolders] = useState(defaultSelectedOptions);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  const handleUpdateGroup = (e) => {
    e.preventDefault();
    let selectedFolderIDs = selectedFolders.map((item) => item.value);
    let data = {
      group_id: groupToUpdate.id,
      selectedFolders: selectedFolderIDs,
    };
    if (data.group_id && data.selectedFolders && data.selectedFolders.length > 0) {
      updateGroupAPI(data);
    } else {
      setApiResponse("Group link and at least one folder is required");
    }
  };

  const updateGroupAPI = (data) => {
    setButtonClicked(true);
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/groups/update_group_folders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            onClose();
            setSelectedFolders([]);
            setTimeout(() => {
              fetchgroupsList();
              setFormData({});
            }, 2000);
            // close popup and refresh the list
          } else {
            setApiResponse(data.error);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors
          setApiResponse(error.messag);
        });
    } catch (error) {
    } finally {
      // Enable the button after 3 seconds
      setTimeout(() => {
        setButtonClicked(false);
      }, 2000);
    }
  };

  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-500">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Update Group folders</h2>

        <form onSubmit={handleUpdateGroup}>
          <div>
            <input type="text" id="group_link" className="input_field" name="group_link" placeholder="Group Link" value={formData.group_link || ""} disabled />
          </div>
          <div className="multi_select">{transformedArray && transformedArray.length > 0 && <MultiSelect options={transformedArray} value={selectedFolders} onChange={setSelectedFolders} labelledBy="Select" />}</div>

          <button type="submit" disabled={buttonClicked} className={`big_button ${buttonClicked ? "disabledButton" : ""}`}>
            Update Folders
          </button>

          <p>{apiResponse}</p>
        </form>
      </div>
    </div>
  );
};

export default UpdateGroup;
