import React, { useState } from "react";

import "./Spintext.css";
const Spintext = () => {
  const [postText, setPostText] = useState("");
  const [displayText, setDisplayText] = useState("");
  const handlePostTextChange = (e) => {
    setPostText(e.target.value);
  };

  const handleButtonClick = () => {
    // var postContent = postText;

    if (postText) {
      const regex = /\[([^\]]+)\]/g;
      let postContent = postText.replace(regex, function (match, options) {
        const optionsArray = options.split("|");
        const randomOption = optionsArray[Math.floor(Math.random() * optionsArray.length)];
        return randomOption;
      });

      setDisplayText(postContent);
    }
  };
  return (
    <div className="spin_input_margin">
      <div className="">
        <textarea id="spin_text" className="spin_text_input" name="spin_text" rows="" cols="" placeholder="Enter spin text here.." value={postText} onChange={handlePostTextChange}></textarea>
      </div>
      <button type="submit" className="spin_text_button" onClick={handleButtonClick}>
        Click here
      </button>

      {<p className="spin_text_result">{displayText}</p>}
    </div>
  );
};

export default Spintext;
