import React from "react";
import "./Facebookaccounts.css";
import { useState, useEffect } from "react";
import CreateAccount from "./CreateAccount";
import Areyousure from "../../../components/popups/Areyousure";
// import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import ReplaceAccount from "./ReplaceAccount";
import Spinner from "../../../components/spinner/Spinner";

const Facebookaccounts = () => {
  // const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let group_id = searchParams.get("group_id");
  const [accountList, setAccountList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [messagesStatus, setMessagesStatus] = useState("");

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isOpenReplace, setIsOpenReplace] = useState(false);
  const [isOpenDelete, setisOpenDelete] = useState(false);
  const [deleteId, setdeleteId] = useState(null);
  const [currentAccount, setCurrentAccount] = useState(null);

  useEffect(() => {
    fetchaccountsList();

    // Call fetchData initially and then every 10 seconds (10000 milliseconds)
    const intervalId = setInterval(fetchaccountsList, 10000);

    // Clean up the interval to avoid memory leaks
    return () => clearInterval(intervalId);
  }, []);

  const fetchaccountsList = async () => {
    try {
      let URL = `${process.env.REACT_APP_BASE_URL}api/facebook/accounts`;

      // need update in api (api is not sending right data with group_id param)
      if (group_id) {
        URL = `${process.env.REACT_APP_BASE_URL}api/facebook/accounts?group_id=${group_id}`;
      }
      const response = await fetch(URL);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();

      setAccountList(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const doOpenDelete = (account_id) => {
    setdeleteId(account_id);
    setisOpenDelete(true);
  };

  const doOpenReplace = (current_account) => {
    setCurrentAccount(current_account);
    setIsOpenReplace(true);
  };

  const doOpenEdit = (current_account) => {
    setCurrentAccount(current_account);
    setIsPopupOpen(true);
  };

  // const openMessage = (account_id) => {
  //   navigate(`/facebook-messages?account_id=${account_id}`);
  // };

  const doDelete = async () => {
    fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/accounts/` + deleteId, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        setisOpenDelete(false);
        fetchaccountsList();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getAccountMessages = async () => {
    // Make a POST request
    try {
      // Disable the button
      setButtonClicked(true);
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/get_account_messages`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();

      if (result && result.success) {
        setMessagesStatus(result.success);
      } else if (result && result.error) {
        setMessagesStatus(result.error);
      }
    } catch (error) {
      setMessagesStatus(error);
    } finally {
      // Enable the button after 3 seconds
      setTimeout(() => {
        setButtonClicked(false);
      }, 3000);
    }
  };

  // if (loading) {
  //   // return <p>Loading...</p>;
  //   return <Spinner />;
  // }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      <div className="top_buttons">
        <button
          className="big_button mr20"
          id="showGroupPopup"
          type="button"
          onClick={() => {
            setCurrentAccount(null);
            setIsPopupOpen(true);
          }}
        >
          Add New Account
        </button>
      </div>
      {isOpenReplace && (
        <ReplaceAccount
          isOpen={isOpenReplace}
          onClose={() => {
            setIsOpenReplace(false);
          }}
          currentAccount={currentAccount}
          fetchaccountsList={fetchaccountsList}
        />
      )}
      {isPopupOpen && (
        <CreateAccount
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          fetchaccountsList={fetchaccountsList}
          accountInfo={currentAccount}
        />
      )}
      <Areyousure
        isOpen={isOpenDelete}
        onNo={() => {
          setisOpenDelete(false);
        }}
        onYes={doDelete}
        popupTitle="Are you sure you want to remove this account?"
      />
      {loading ? (
        // <h1>Loading...</h1>
        <Spinner />
      ) : (
        <div className="active_list_container">
          {accountList && accountList.length > 0 ? (
            <div className="active_lists">
              {accountList &&
                accountList.map((account) => (
                  <div key={account.id} className="group_parent_div">
                    <img className="img_round" src={account.image} width="120" height="120" alt=" " />
                    <div className="active_list mpt0">
                      <div className="group_name_div">{account.name}</div>
                      <div className="active_account">
                        <div>{account.email}</div>
                        <div>{account.password}</div>
                      </div>
                      <div className="group_stats">
                        <div>Groups:</div>
                        <div>
                          <a href={"facebook-groups?account_id=" + account.id}>
                            <b>{account.total_groups}</b>
                          </a>
                        </div>
                        <div>Posts:</div>
                        <div>
                          <a href={"facebook-groups?account_id=" + account.id}>
                            <b>{account.total_posts}</b>
                          </a>
                        </div>
                        <div>Messages:</div>
                        <div>
                          <a href={"facebook-messages?account_id=" + account.id}>
                            <b>{account.total_messages}</b>
                          </a>
                        </div>
                      </div>

                      <div className="account_info" title={account.user_info}>
                        {account.user_info}
                      </div>

                      <div className="buttons_group">
                        <div className="talr">
                          <div
                            className="small_button"
                            onClick={() => {
                              // openMessage(account.id);
                              doOpenEdit(account);
                            }}
                          >
                            Edit
                          </div>
                        </div>
                        <div className="talr">
                          <div
                            className="small_button button_orange"
                            onClick={() => {
                              // openMessage(account.id);
                              doOpenReplace(account);
                            }}
                          >
                            Replace
                          </div>
                        </div>
                        <div className="talr">
                          <div
                            className="small_button button_orange"
                            onClick={() => {
                              doOpenDelete(account.id);
                            }}
                          >
                            Delete
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <h1>No Facebook Account found!</h1>
          )}
        </div>
      )}
      <div className="post-button-container">
        <div className="post-button-div">
          <button className={`big_button ${buttonClicked ? "disabledButton" : ""}`} onClick={getAccountMessages} disabled={buttonClicked}>
            Fetch Facebook Messages
          </button>
          <div>{messagesStatus}</div>
        </div>
      </div>
    </div>
  );
};

export default Facebookaccounts;
