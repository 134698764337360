import React, { useEffect, useState } from "react";
import "./FacebookLogs.css";
import Spinner from "../../../components/spinner/Spinner";

const FacebookLogs = () => {
  const [logsList, setLogsList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchLogsList();
  }, []);

  const fetchLogsList = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/facebook/logs`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setLogsList(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    // return <p>Loading...</p>;
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <>
      <div className="logs-container">
        {logsList && logsList.length > 0 ? (
          <>
            <h2 className="logs-heading">Facebook Logs</h2>
            <table className="logs-table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Message</th>
                  <th>Timestamps</th>
                </tr>
              </thead>
              <tbody>
                {logsList &&
                  logsList.map((log) => (
                    <tr key={log.id} className={`log-item ${log.status}`}>
                      <td>{log.type}</td>
                      <td>{log.status}</td>
                      <td>{log.text}</td>
                      <td>{new Date(log.timestamps).toLocaleString()}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        ) : (
          <h1>No Logs found</h1>
        )}
      </div>
    </>
  );
};

export default FacebookLogs;
