import React, { useEffect, useState } from "react";

const CreateCraigslistPost = ({ isOpen, onClose, loadCraigslistList }) => {
  const [formData, setFormData] = useState({});
  const [apiResponse, setApiResponse] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);

  const [currentStep, setStep] = useState(1);
  const [images, setImages] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeChecked = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked ? 1 : 0,
    }));
  };

  const createCraigslistAPI = (e) => {
    e.preventDefault();
    try {
      // Disable the button
      setButtonClicked(true);
      let data = new FormData();

      Object.keys(formData).forEach((ky) => {
        data.append(ky, formData[ky]);
      });

      images.forEach((img) => {
        data.append(`images`, img);
      });

      fetch(`${process.env.REACT_APP_BASE_URL}api/craigslist-add`, {
        method: "POST",
        body: data,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            onClose();
            loadCraigslistList();
            // close popup and refresh the list
          } else {
            setApiResponse(data.error);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors
          // setApiResponse(error.messag);
        });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Enable the button after 3 seconds
      setTimeout(() => {
        setButtonClicked(false);
      }, 2000);
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map((file) => {
      var newFile = file;
      newFile["preview"] = URL.createObjectURL(file);
      return newFile;
    });
    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleDeleteImage = (imageToDelete) => {
    setImages(images.filter((image) => image["preview"] !== imageToDelete["preview"]));
  };

  var subTypes = [0, ["rooms & shares", "apartments / housing for rent (no shares, roommates, or sublets please!)", "housing swap", "office & commercial ($5)", "parking & storage", "real estate - by broker", "real estate - by owner", "sublets & temporary", "vacation rentals"], ["wanted: apts", "wanted: real estate", "wanted: room/share", "wanted: sublet/temp"]];

  return (
    <div className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content popup-content-500">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Add New Post</h2>

        <form onSubmit={createCraigslistAPI}>
          <div className="craig_step" style={currentStep === 1 ? { display: "block" } : { display: "none" }}>
            <div className="craig_title">Select Post Type</div>
            <div className="craig_fields craig_fields_radio">
              <input type="radio" name="craig_type" id="hoffer" value="1" checked={formData.craig_type && formData.craig_type === "1"} onClick={handleChange} />
              <label htmlFor="hoffer">Housing offered</label>
              <br />
              <input type="radio" name="craig_type" id="hwanted" value="2" checked={formData.craig_type && formData.craig_type === "2"} onClick={handleChange} />
              <label htmlFor="hwanted">Housing wanted</label>
            </div>

            <button
              type="button"
              className={`big_button ${!formData.craig_type ? "disabledButton" : ""}`}
              disabled={!formData.craig_type}
              onClick={() => {
                if (!!formData.craig_type) {
                  setStep(2);
                }
              }}
            >
              Next
            </button>
          </div>
          <div className="craig_step" style={currentStep === 2 ? { display: "block" } : { display: "none" }}>
            <div className="craig_title">Select Post Sub-Type</div>
            <div className="craig_fields craig_fields_radio">
              {subTypes &&
                subTypes[formData.craig_type] &&
                subTypes[formData.craig_type].map((subType, index) => (
                  <div key={index}>
                    <input type="radio" name="craig_subtype" id={"hoffer" + index} value={index} checked={formData.craig_subtype && formData.craig_subtype === index + ""} onClick={handleChange} />
                    <label htmlFor={"hoffer" + index}>{subType}</label>
                  </div>
                ))}
            </div>
            <button
              type="button"
              className="big_button"
              onClick={() => {
                setStep(1);
              }}
            >
              Back
            </button>{" "}
            &nbsp;
            <button
              type="button"
              className={`big_button ${!formData.craig_subtype ? "disabledButton" : ""}`}
              disabled={!formData.craig_subtype}
              onClick={() => {
                if (!!formData.craig_subtype) {
                  setStep(3);
                }
              }}
            >
              Next
            </button>
          </div>
          <div className="craig_step" style={currentStep === 3 ? { display: "block" } : { display: "none" }}>
            <div className="craig_title">Post Details</div>
            <div>
              <input type="text" id="post_name" className="input_field" name="post_name" placeholder="Post Title" value={formData.post_name || ""} onChange={handleChange} />
            </div>
            <div>
              <textarea rows="4" id="post_description" className="input_field" name="post_description" placeholder="Post Description" value={formData.post_description || ""} onChange={handleChange} />
            </div>
            <div>
              <input type="text" id="post_city" className="input_field" name="post_city" placeholder="City (optional) (write % to use subdomain city)" value={formData.post_city || ""} onChange={handleChange} />
            </div>
            {formData.craig_type === "1" && (
              <div>
                <input type="phone" id="post_zip" className="input_field" name="post_zip" placeholder="Zip Code" value={formData.post_zip || ""} onChange={handleChange} />
              </div>
            )}
            <button
              type="button"
              className="big_button"
              onClick={() => {
                setStep(2);
              }}
            >
              Back
            </button>{" "}
            &nbsp;
            <button
              type="button"
              className={`big_button ${!formData.post_name ? "disabledButton" : ""}`}
              disabled={!formData.post_name}
              onClick={() => {
                if (!!formData.post_name) {
                  setStep(4);
                }
              }}
            >
              Next
            </button>
          </div>
          <div className="craig_step" style={currentStep === 4 ? { display: "block" } : { display: "none" }}>
            <div className="craig_title">Additional Details</div>
            {formData.craig_type === "1" && (
              <>
                <div>
                  <input type="text" id="post_price" className="input_field" name="post_price" placeholder="Price" value={formData.post_price || ""} onChange={handleChange} />
                </div>
                <div>
                  <input type="text" id="post_sqft" className="input_field" name="post_sqft" placeholder="Sqft" value={formData.post_sqft || ""} onChange={handleChange} />
                </div>
                <div>
                  <div className="craig_field_title">Housing Type</div>
                  <select name="post_house_type" id="post_house_type" className="input_field" onChange={handleChange}>
                    <option value="1" selected={formData.post_house_type === "1"}>
                      apartment
                    </option>
                    <option value="2" selected={formData.post_house_type === "2"}>
                      condo
                    </option>
                    <option value="3" selected={formData.post_house_type === "3"}>
                      cottage/cabin
                    </option>
                    <option value="4" selected={formData.post_house_type === "4"}>
                      duplex
                    </option>
                    <option value="5" selected={formData.post_house_type === "5"}>
                      flat
                    </option>
                    <option value="6" selected={formData.post_house_type === "6"}>
                      house
                    </option>
                    <option value="7" selected={formData.post_house_type === "7"}>
                      in-law
                    </option>
                    <option value="8" selected={formData.post_house_type === "8"}>
                      loft
                    </option>
                    <option value="9" selected={formData.post_house_type === "9"}>
                      townhouse
                    </option>
                    <option value="10" selected={formData.post_house_type === "10"}>
                      manufactured
                    </option>
                    <option value="11" selected={formData.post_house_type === "11"}>
                      assisted living
                    </option>
                    <option value="12" selected={formData.post_house_type === "12"}>
                      land
                    </option>
                  </select>
                </div>
                <div>
                  <div className="craig_field_title">Laundry</div>
                  <select name="post_house_laundry" id="post_house_laundry" className="input_field" onChange={handleChange}>
                    <option value="" selected={!formData.post_house_laundry}>
                      -
                    </option>
                    <option value="1" selected={formData.post_house_laundry === "1"}>
                      w/d in unit
                    </option>
                    <option value="4" selected={formData.post_house_laundry === "4"}>
                      w/d hookups
                    </option>
                    <option value="2" selected={formData.post_house_laundry === "2"}>
                      laundry in bldg
                    </option>
                    <option value="3" selected={formData.post_house_laundry === "3"}>
                      laundry on site
                    </option>
                    <option value="5" selected={formData.post_house_laundry === "5"}>
                      no laundry on site
                    </option>
                  </select>
                </div>
                <div>
                  <div className="craig_field_title">Parking</div>
                  <select name="post_house_parking" id="post_house_parking" className="input_field" onChange={handleChange}>
                    <option value="" selected={!formData.post_house_parking}>
                      -
                    </option>
                    <option value="1" selected={formData.post_house_parking === "1"}>
                      carport
                    </option>
                    <option value="2" selected={formData.post_house_parking === "2"}>
                      attached garage
                    </option>
                    <option value="3" selected={formData.post_house_parking === "3"}>
                      detached garage
                    </option>
                    <option value="4" selected={formData.post_house_parking === "4"}>
                      off-street parking
                    </option>
                    <option value="5" selected={formData.post_house_parking === "5"}>
                      street parking
                    </option>
                    <option value="6" selected={formData.post_house_parking === "6"}>
                      valet parking
                    </option>
                    <option value="7" selected={formData.post_house_parking === "7"}>
                      no parking
                    </option>
                  </select>
                </div>
                <div>
                  <div className="craig_field_title">Bedrooms</div>
                  <select name="post_house_bedrooms" id="post_house_bedrooms" className="input_field" onChange={handleChange}>
                    <option value="" selected={!formData.post_house_bedrooms && formData.post_house_bedrooms !== "0"}>
                      -
                    </option>
                    <option value="0" selected={formData.post_house_bedrooms === "0"}>
                      0
                    </option>
                    <option value="1" selected={formData.post_house_bedrooms === "1"}>
                      1
                    </option>
                    <option value="2" selected={formData.post_house_bedrooms === "2"}>
                      2
                    </option>
                    <option value="3" selected={formData.post_house_bedrooms === "3"}>
                      3
                    </option>
                    <option value="4" selected={formData.post_house_bedrooms === "4"}>
                      4
                    </option>
                    <option value="5" selected={formData.post_house_bedrooms === "5"}>
                      5
                    </option>
                    <option value="6" selected={formData.post_house_bedrooms === "6"}>
                      6
                    </option>
                    <option value="7" selected={formData.post_house_bedrooms === "7"}>
                      7
                    </option>
                    <option value="8" selected={formData.post_house_bedrooms === "8"}>
                      8
                    </option>
                  </select>
                </div>
                <div>
                  <div className="craig_field_title">Bathrooms</div>
                  <select name="post_house_bathrooms" id="post_house_bathrooms" className="input_field" onChange={handleChange}>
                    <option value="" selected={!formData.post_house_bathrooms && formData.post_house_bathrooms !== "0"}>
                      -
                    </option>
                    <option value="1" selected={formData.post_house_bathrooms === "1"}>
                      shared
                    </option>
                    <option value="2" selected={formData.post_house_bathrooms === "2"}>
                      split
                    </option>
                    <option value="3" selected={formData.post_house_bathrooms === "3"}>
                      1
                    </option>
                    <option value="4" selected={formData.post_house_bathrooms === "4"}>
                      1.5
                    </option>
                    <option value="5" selected={formData.post_house_bathrooms === "5"}>
                      2
                    </option>
                    <option value="6" selected={formData.post_house_bathrooms === "6"}>
                      2.5
                    </option>
                    <option value="7" selected={formData.post_house_bathrooms === "7"}>
                      3
                    </option>
                    <option value="8" selected={formData.post_house_bathrooms === "8"}>
                      3.5
                    </option>
                    <option value="9" selected={formData.post_house_bathrooms === "9"}>
                      4
                    </option>
                    <option value="10" selected={formData.post_house_bathrooms === "10"}>
                      4.5
                    </option>
                    <option value="11" selected={formData.post_house_bathrooms === "11"}>
                      5
                    </option>
                    <option value="12" selected={formData.post_house_bathrooms === "12"}>
                      5.5
                    </option>
                    <option value="13" selected={formData.post_house_bathrooms === "13"}>
                      6
                    </option>
                    <option value="14" selected={formData.post_house_bathrooms === "14"}>
                      6.5
                    </option>
                    <option value="15" selected={formData.post_house_bathrooms === "15"}>
                      7
                    </option>
                    <option value="16" selected={formData.post_house_bathrooms === "16"}>
                      7.5
                    </option>
                    <option value="17" selected={formData.post_house_bathrooms === "17"}>
                      8
                    </option>
                    <option value="18" selected={formData.post_house_bathrooms === "18"}>
                      8.5
                    </option>
                    <option value="19" selected={formData.post_house_bathrooms === "19"}>
                      9+
                    </option>
                  </select>
                </div>
                <div className="craig_fields_checkbox">
                  <input type="checkbox" name="post_house_cats" id="post_house_cats" value="1" checked={!!formData.post_house_cats} onChange={handleChangeChecked} />
                  <label htmlFor="post_house_cats">Cats ok</label>
                </div>
                <div className="craig_fields_checkbox">
                  <input type="checkbox" name="post_house_dogs" id="post_house_dogs" value="1" checked={!!formData.post_house_dogs} onChange={handleChangeChecked} />
                  <label htmlFor="post_house_dogs">Dogs ok</label>
                </div>
                <div className="craig_fields_checkbox">
                  <input type="checkbox" name="post_house_furnished" id="post_house_furnished" value="1" checked={!!formData.post_house_furnished} onChange={handleChangeChecked} />
                  <label htmlFor="post_house_furnished">Furnished</label>
                </div>
                <div className="craig_fields_checkbox">
                  <input type="checkbox" name="post_house_smoking" id="post_house_smoking" value="1" checked={!!formData.post_house_smoking} onChange={handleChangeChecked} />
                  <label htmlFor="post_house_smoking">No Smoking</label>
                </div>
                <div className="craig_fields_checkbox">
                  <input type="checkbox" name="post_house_wheelchair" id="post_house_wheelchair" value="1" checked={!!formData.post_house_wheelchair} onChange={handleChangeChecked} />
                  <label htmlFor="post_house_wheelchair">Wheelchair Accessible</label>
                </div>
                <div className="craig_fields_checkbox">
                  <input type="checkbox" name="post_house_ac" id="post_house_ac" value="1" checked={!!formData.post_house_ac} onChange={handleChangeChecked} />
                  <label htmlFor="post_house_ac">Air Conditioning</label>
                </div>
                <div className="craig_fields_checkbox">
                  <input type="checkbox" name="post_house_ev" id="post_house_ev" value="1" checked={!!formData.post_house_ev} onChange={handleChangeChecked} />
                  <label htmlFor="post_house_ev">EV Charging</label>
                </div>
              </>
            )}
            {formData.craig_type === "2" && (
              <>
                <div className="craig_fields_checkbox">
                  <input type="checkbox" name="post_house_cats" id="post_house_cats" value="1" checked={!!formData.post_house_cats} onChange={handleChangeChecked} />
                  <label htmlFor="post_house_cats">Cats ok</label>
                </div>
                <div className="craig_fields_checkbox">
                  <input type="checkbox" name="post_house_dogs" id="post_house_dogs" value="1" checked={!!formData.post_house_dogs} onChange={handleChangeChecked} />
                  <label htmlFor="post_house_dogs">Dogs ok</label>
                </div>
                <div className="craig_fields_checkbox">
                  <input type="checkbox" name="post_house_ac" id="post_house_ac" value="1" checked={!!formData.post_house_ac} onChange={handleChangeChecked} />
                  <label htmlFor="post_house_ac">Air Conditioning</label>
                </div>
              </>
            )}
            <button
              type="button"
              className="big_button"
              onClick={() => {
                setStep(3);
              }}
            >
              Back
            </button>{" "}
            &nbsp;
            <button
              type="button"
              className={`big_button ${!formData.post_name ? "disabledButton" : ""}`}
              disabled={!formData.post_name}
              onClick={() => {
                if (!!formData.post_name) {
                  setStep(5);
                }
              }}
            >
              Next
            </button>
          </div>
          <div className="craig_step" style={currentStep === 5 ? { display: "block" } : { display: "none" }}>
            <div className="craig_title">Images</div>
            <div>
              <input type="file" className="craig_image_add" multiple onChange={handleImageChange} />
            </div>
            <div className="craig_images">
              <div className="image-list">
                {images.map((image, index) => (
                  <div key={index} className="image-container">
                    <img src={image["preview"]} alt={`preview-${index}`} className="image-preview" />
                    <button type="button" className="delete-button" onClick={() => handleDeleteImage(image)}>
                      &times;
                    </button>
                  </div>
                ))}
              </div>
              {images.length === 0 && <div className="craig_images_noimage">Add multiple images</div>}
            </div>
            <button
              type="button"
              className="big_button"
              onClick={() => {
                setStep(3);
              }}
            >
              Back
            </button>{" "}
            &nbsp;
            <button type="submit" className={`big_button ${buttonClicked || images.length === 0 ? "disabledButton" : ""}`} disabled={images.length === 0}>
              Create
            </button>
          </div>

          <p>{apiResponse}</p>
        </form>
      </div>
    </div>
  );
};

export default CreateCraigslistPost;
